import React, {useContext, useState} from "react";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ZenModal from "../../../../../components/ZenModal";
import ZenDropdown from "../../../../../components/ZenDropdown";
import {EAlertType, GlobalAlertContext} from "../../../../../components/GlobalAlert";
import ApiClient from "../../../../../lib/ApiClient";
import ZenButton from "../../../../../components/ZenButton";
import {GlobalContext} from "../../../../../lib/GlobalContextProvider";
import ZenMiniAlert, {EMiniAlertType} from "../../../../../components/ZenMiniAlert";
import {KanbanBoardContext} from "../../index";
import {ETaskStatus, IZenContext, IZenTask} from "@yellowmelon/zen-global-types";

interface Props{
    className?: string
    columnStatus: ETaskStatus
    columnTasks: IZenTask[]
}

const apiClient = new ApiClient();

const KanbanColumnMenu = ({className, columnStatus, columnTasks}: Props)=>{

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [selectedContext, setSelectedContext] = useState<IZenContext | null>(null);
    const [updateSuccessful, setUpdateSuccessful] = useState<boolean>(false)
    const {showAlert} = useContext(GlobalAlertContext);
    const {availableContexts, currentContext} = useContext(GlobalContext)
    const {getTaskList} = useContext(KanbanBoardContext);


    const selectContext = (index: number | null | undefined) => {

        if (typeof index !== "number") {
            setSelectedContext(null);
            return;
        }

        const context = availableContexts[index]

        if (!context?._id) return;

        setSelectedContext(context);

    }

    const applyContext = async ()=> {

        if(!confirm('This will update all the tasks in this column with your chosen context'))
            return;

        try{

            await apiClient.put(`api/v1/tasks/applycontext/${columnStatus}/${selectedContext?._id ? selectedContext?._id : 'none' }`)
            setUpdateSuccessful(true);

            getTaskList(currentContext?._id?.toString())

            setTimeout(
                ()=>{
                    setUpdateSuccessful(false);
                },3000
            )

        }catch (err: any){

            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

        }

    }

    const deleteAllTasks = async ()=>{

        if(!confirm('Delete all tasks in this column?'))
            return;

        try{

            await apiClient.put(`api/v1/tasks/bulkdelete`, { payload : { tasks: columnTasks.map(( columnTask ) => columnTask._id ) } })
            setUpdateSuccessful(true);

            getTaskList(currentContext?._id?.toString())

            setTimeout(
                ()=>{
                    setUpdateSuccessful(false);
                },3000
            )

        }catch (err: any){

            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

        }

    }

    return(
        <div className={`kanban-column-menu flex ${className ? className : ''}`}>

            <ZenModal
                title={`Manage ${columnStatus} tasks`}
                showModal={showMenu}
                buttons={ updateSuccessful && <ZenMiniAlert type={EMiniAlertType.success} message={'update successful'} className={'ml-auto mr-2'} /> }
                closeModal={ ()=>{ setShowMenu(false) } } >
                <div className={'p-4'}>

                    { !!availableContexts.length &&

                        <div className={'flex items-center'}>
                            <label htmlFor="context"
                                   className="text-sm dark:text-white text-left mr-2 font-bold">Choose context for all tasks</label>

                            <ZenDropdown<IZenContext>
                                selectNoneOption={true}
                                selectedItem={selectedContext?._id?.toString()}
                                items={availableContexts}
                                onClick={(index) => {
                                    selectContext(index)
                                }
                                }/>

                            <ZenButton className={'ml-2'} label={'Apply'} onClick={ ()=>{ applyContext() } } />

                        </div>

                    }

                    <div className={'flex align-middle justify-end pt-4'}>

                        <ZenButton type={'button'} zenType={'danger'} className={'justify-end'} label={'Delete all tasks in this colum'} onClick={ deleteAllTasks } />

                    </div>

                </div>
            </ZenModal>

            <button
                className={'border-0 bg-none'}
                onClick={ ()=>{ setShowMenu(true) } }
                type={'button'}>
                <FontAwesomeIcon icon={faEllipsis}/>
            </button>

        </div>
    )

}

export default KanbanColumnMenu

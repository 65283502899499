import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {EAlertType, GlobalAlertContext} from "../../components/GlobalAlert";
import ApiClient from "../ApiClient";
import {AuthContext} from "../../components/Authenticate";
import {IZenContext} from "@yellowmelon/zen-global-types";


export interface IGlobalContext{
    currentContext: IZenContext | null;
    updateContext: (context: IZenContext | null) => void
    availableContexts: IZenContext[]
    getContexts: ()=> void
    filterToday: boolean
    updateFilterToday: (filter: boolean) => void
}

export const GlobalContext = createContext<IGlobalContext>(
    {
        currentContext: null,
        updateContext: (context)=> {},
        availableContexts: [],
        getContexts: ()=> {},
        filterToday: false,
        updateFilterToday: (filter) => {},
    }
)

const apiClient = new ApiClient();

const GlobalContextProvider = ({children}: {children: ReactNode}) => {

    const [currentContext, setCurrentContext] = useState<IZenContext | null>(null);
    const [availableContexts, setAvailableContexts] = useState<IZenContext[]>([]);
    const [filterToday, setFilterToday] = useState<boolean>(false);

    const {showAlert} = useContext(GlobalAlertContext)
    const { loggedIn } = useContext(AuthContext)

    const getContexts = async () => {

        apiClient.get<IZenContext[]>(`api/v1/contexts`).then(
            (contexts) => {

                setAvailableContexts(contexts);

            }
        ).catch(
            (err: any) => {
                console.error(`Error getting contexts: ${err.message}`);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
            }
        )


    }

    const updateContext = (context: IZenContext | null) => {

        if(context === null){
            localStorage.removeItem('zen-context');
        }

        context?._id?.toString() && localStorage.setItem('zen-context', context?._id?.toString())
        setCurrentContext(context);

    }

    const updateFilterToday = (newFilter: boolean) => {

        if(!newFilter){
            localStorage.removeItem('zen-filter-today');
        }else{
            localStorage.setItem('zen-filter-today', 'true')
        }

        setFilterToday(newFilter);

    }

    useEffect(
        ()=>{

            const storedFilter = localStorage.getItem('zen-filter-today');

            if(storedFilter === 'true'){
                setFilterToday(true);
            }

        },[]
    )

    useEffect(
        ()=>{

            if(!availableContexts.length) return

            const contextId = localStorage.getItem('zen-context');
            const context: IZenContext | undefined = availableContexts.find((item: IZenContext) => item._id?.toString() === contextId);

            if(context){
                setCurrentContext(context);
            }

        },[availableContexts]
    )

    useEffect(
        ()=>{

            loggedIn && getContexts()

        },[loggedIn]
    )

    return(
        <GlobalContext.Provider value={{currentContext, updateContext, getContexts, availableContexts, filterToday, updateFilterToday}}>
            {children}
        </GlobalContext.Provider>
    )

}

export default GlobalContextProvider




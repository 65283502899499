import React from 'react';
import ZenButton from "../../../../components/ZenButton";
import {IZenTask} from "@yellowmelon/zen-global-types";

interface Props{
    task: IZenTask
}

const GoalChildTaskCard = ({task}: Props) => {


    return(
        <div
            className='bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 mb-2 p-4 pb-3'>
            <div className="text-left mb-2">

                <div className={'flex align-middle'}>

                    <h3 className="font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                        {task.title}
                    </h3>

                </div>

                <p className="text-sm text-gray-500">
                    {task.description}
                </p>

                <div className={'item-actions flex justify-end w-full pt-2'}>

                    <ZenButton label={'delete'} size={'xs'} zenType={'danger'} className={'mr-1'} onClick={() => {
                        // deleteItem()
                    }}/>



                </div>

            </div>
        </div>

    )

}

export default GoalChildTaskCard;

import config from "../../config/config";
import axios, {AxiosResponse, HeadersDefaults} from "axios/index";
import {ManageBearerToken} from "./ManageBearerToken";

export interface IApiRejectReason {
    reason?: string,
    message?: string
}

export interface IApiSuccessResponse<T> extends AxiosResponse<T> {
    token?: string
}

interface IClientHeader extends HeadersDefaults {
    Authorization?: string | null
}

interface IPostConfig {
    payload?: object
    headers?: object
}

const tokenManager = new ManageBearerToken();

export default class ApiClient {

    apiServer = config.apiServer;

    get<T>(url: string, query?: object) {

        return new Promise<T>(async (resolve, reject) => {

            try {

                let headerObject;

                if (query) {
                    headerObject = {params: query}
                }

                const token = await tokenManager.getToken()

                if (token) {
                    headerObject = headerObject ? {
                        ...headerObject,
                        headers: {Authorization: `Bearer ${token}`}
                    } : {headers: {Authorization: `Bearer ${token}`}}
                }

                const response = await axios.get<IApiSuccessResponse<T>>(`${this.apiServer}/${url}`, headerObject);

                const data: IApiSuccessResponse<T> = response.data;
                resolve(data as T);

            } catch (err: any) {

                console.error(err);
                const {message, response} = err;

                reject({reason: response?.data?.error, message})
            }
        });

    }

    post<T>(url: string, requestConfig: IPostConfig = {}): Promise<T> {

        return new Promise<T>(async (resolve, reject) => {

            try {

                let headers = {...requestConfig.headers}

                const token = await tokenManager.getToken()

                if (token) {
                    headers = {...headers, Authorization: `Bearer ${token}`}
                }

                const response = await axios.post<IApiSuccessResponse<T>>(`${this.apiServer}/${url}`, requestConfig.payload, {headers});

                const data: IApiSuccessResponse<T> = response.data
                resolve(data as T);

            } catch (err: any) {

                console.error(err);
                const {message, response, request} = err;

                reject({reason: response?.data?.error, message, status: response?.status})
            }
        });
    }

    put<T>(url: string, requestConfig: IPostConfig = {}): Promise<T> {

        return new Promise<T>(async (resolve, reject) => {

            try {

                let headers = {...requestConfig.headers}
                const token = await tokenManager.getToken()

                if (token) {
                    headers = {...headers, Authorization: `Bearer ${token}`}
                }

                const response = await axios.put<IApiSuccessResponse<T>>(`${this.apiServer}/${url}`, requestConfig.payload, {headers});

                const data: IApiSuccessResponse<T> = response.data
                resolve(data as T);

            } catch (err: any) {

                console.error(err);
                const {message, response, request} = err;

                reject({reason: response?.data, message, status: response?.status})
            }
        });
    }

    delete<T>(url: string, requestConfig: IPostConfig = {}): Promise<T> {

        return new Promise(async (resolve, reject) => {

            try {

                let headers = {...requestConfig.headers}
                const token = await tokenManager.getToken()

                if (token) {
                    headers = {...headers, Authorization: `Bearer ${token}`}
                }

                const response = await axios.delete<T>(`${this.apiServer}/${url}`, {headers});

                resolve(response.data as T);

            } catch (err: any) {

                console.error(err);
                const {message, response} = err;

                reject({reason: response?.data?.error, message})
            }
        });

    }

}

import React, {useEffect, useRef, useState} from "react";
import {IZenChecklistItem} from "@yellowmelon/zen-global-types";
import ZenButton from "../../../../../../components/ZenButton";
import ZenDeleteButton from "../../../../../../components/ZenDeleteButton";
import {useDrag, useDrop} from "react-dnd";
import {Identifier} from "dnd-core";
import {IDragItem} from "../../KanbanCard";

interface IChecklistItemProps {
    index: number
    checklistItem: IZenChecklistItem
    onChange: (value: boolean) => void
    deleteItem: () => void
    moveItem: (dragIndex: number, hoverIndex: number) => void
    onDrop: () => void
}

const ZenChecklistItem = ({index, checklistItem, onChange, deleteItem, moveItem, onDrop}: IChecklistItemProps) => {

    const ref = useRef(null);

    const [{handlerId, isOver}, drop] = useDrop<
        IDragItem,
        void,
        { handlerId: Identifier | null, isOver: boolean }>(
        {
            accept: 'checklistItem',
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                    isOver: monitor.isOver()
                }
            },
            hover(item: IDragItem, monitor) {

                if (!ref.current) {
                    return
                }

                const dragIndex = item.index
                const hoverIndex = index

                moveItem(dragIndex, hoverIndex)

            },
            drop: (update: unknown, monitor) => {

                const {task} = update as IDragItem

                console.log('item dropped');
                console.log(monitor);
                console.log(update);

                // onDrop(task);

            },
        })

    const [{isDragging}, drag] = useDrag({
        type: 'checklistItem',
        item: () => {

            return {index}
        },
        collect: (monitor: any) => {

            return {
                isDragging: monitor.isDragging(),
            }

        },
    })

    drag(drop(ref))

    return (
        <div ref={ref} className="flex zen-checklist-item mb-4">
            <input type="checkbox"
                   checked={checklistItem.completed}
                   onChange={(ev: any) => {
                       onChange(ev.target.checked)
                   }}
                   className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            />
            <span
                className={`text-sm text-gray-500 ms-3 dark:text-gray-400 ${checklistItem.completed ? 'line-through' : ''}`}>
                    {checklistItem.label}
                </span>
            <ZenDeleteButton
                confirmMessage={'Delete this checklist item?'}
                onDelete={deleteItem}
                className={'ml-auto mr-4 text-red-500'}/>
        </div>
    )

}


interface Props {
    checklistItems: IZenChecklistItem[]
    updateParent: (checklist: IZenChecklistItem[]) => void
}

const defaultItem: IZenChecklistItem = {
    label: '',
    index: 0,
    completed: false
}


const ZenChecklist = ({checklistItems, updateParent}: Props) => {

    const [checklist, setChecklist] = useState<IZenChecklistItem[]>(checklistItems);
    const [newItem, setNewItem] = useState<IZenChecklistItem>(defaultItem);

    useEffect(
        () => {

            setChecklist(checklistItems);

        }, [checklistItems]
    )

    const itemChecked = (updatedItem: IZenChecklistItem, completed: boolean) => {

        const updatedList: IZenChecklistItem[] = checklist.map(
            (item: IZenChecklistItem) => {

                if (item.index === updatedItem.index) {
                    return {...updatedItem, completed}
                }

                return item

            }
        )

        updateParent(updatedList);

    }

    const deleteItem = (index: number) => {

        const updatedList: IZenChecklistItem[] =
            checklist.filter((item: IZenChecklistItem) => item.index !== index)
                .map((item: IZenChecklistItem, index) => ({...item, index})); // Update remaining item indeces

        updateParent(updatedList);

    }

    const moveItem = (dragIndex: number, hoverIndex: number) => {

        // console.log(dragIndex);
        // console.log(hoverIndex);

        const updatedList: IZenChecklistItem[] = [...checklistItems];

        const dragItem = updatedList[dragIndex];
        const hoverItem = updatedList[hoverIndex]

        if (hoverItem.index === dragItem.index) return;

        updatedList[hoverIndex] = updatedList[dragIndex];
        setChecklist(updatedList);

    }

    return (
        <div onClick={ (ev) => { ev.preventDefault(); ev.stopPropagation(); } }>

            <label htmlFor="zen-task-checklist"
                   className="block text-sm mb-4 dark:text-white text-left">Checklist</label>

            <div id={'zen-task-checklist'}>

                {
                    !!checklist.length && checklist.map(
                        (item: IZenChecklistItem, index) =>
                            <ZenChecklistItem
                                key={index}
                                index={index}
                                checklistItem={item}
                                deleteItem={() => {
                                    deleteItem(index);
                                }}
                                moveItem={moveItem}
                                onDrop={() => {
                                }}
                                onChange={(checked: boolean) => {
                                    itemChecked(item, checked)
                                }}/>
                    )
                }

            </div>
            <div onClick={ (ev) => { ev.preventDefault(); ev.stopPropagation(); } } className={'flex items-center'}>

                <input type="text"
                       id="title"
                       placeholder={'Enter a new checklist item'}
                       onKeyDown={
                           (ev) => {

                               if (ev.key === 'Enter') {

                                   ev.preventDefault();
                                   ev.stopPropagation();

                                   updateParent([...checklist, newItem]);
                                   setNewItem({...defaultItem, index: checklist.length + 1, started: new Date().toISOString()})
                               }
                           }
                       }
                       value={newItem.label}
                       onChange={(event) => {

                           setNewItem({...newItem, label: event.target.value})

                       }}
                       className="py-2 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                       aria-describedby="title"/>

                <ZenButton
                    className={'w-[100px] ml-4 py-2 disabled:opacity-50'}
                    disabled={ !newItem.label.length }
                    label={'Add Item'}
                    zenType={'primary'}
                    type={'button'}
                    onClick={
                        () => {

                            updateParent([...checklist, newItem]);
                            // Create next item in list
                            setNewItem({...defaultItem, index: checklist.length + 1, started: new Date().toISOString()})
                        }
                    }/>

            </div>

        </div>
    )

}

export default ZenChecklist;

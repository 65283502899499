import React, {useContext, useEffect, useState} from "react";
import {IZenGoal, IZenGoalNote} from "@yellowmelon/zen-global-types";
import GoalNoteModal from "./GoalNoteModal";
import ZenModal from "../../../components/ZenModal";
import ZenButton from "../../../components/ZenButton";
import ZenMiniAlert, {EMiniAlertType} from "../../../components/ZenMiniAlert";
import ApiClient from "../../../lib/ApiClient";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import GoalNote from "./GoalNote";

interface Props {
    goal: IZenGoal
    getGoal: ()=> void
    updateGoal: (updatedGoal: IZenGoal, saveGoal?: boolean) => void
}

const ModalButtons = ({saveNote, saved}: { saveNote: ()=>void, saved: boolean })=>(
    <>
        <ZenMiniAlert type={ saved ? EMiniAlertType.success : EMiniAlertType.danger} message={ saved ? 'saved' : 'not saved' }/>
        <ZenButton
            label='Save note'
            zenType={'primary'}
            size='md'
            onClick={()=>{ saveNote() }}
            className='mr-2 py-5' />
    </>
)

const GoalNotes = ({goal, updateGoal, getGoal}: Props) => {


    const apiClient = new ApiClient();
    const [showAddNoteModal, setShowAddNoteModal] = useState<boolean>(false);
    const [noteSaved, setNoteSaved] = useState<boolean>(false);
    const [note, setNote] = useState<IZenGoalNote>(
        {
            index: 0,
            content: ''
        }
    );

    const {showAlert} = useContext(GlobalAlertContext);

    const addOrEditNote = async () => {

        try {

            const editing = !!note._id

            const updatedNote = editing ? await apiClient.put<IZenGoalNote>(`api/v1/goals/notes/${goal._id}`, {payload: {note}}) :
                await apiClient.post<IZenGoalNote>(`api/v1/goals/notes/${goal._id}`, {payload: {content: note.content}});

            setNote(updatedNote);
            setNoteSaved(true);
            getGoal();

        } catch (err: any) {

            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

        }

    }

    const deleteNote = async (noteToDelete: IZenGoalNote)=>{

        try {

            const updatedGoal: IZenGoal = await apiClient.delete(`api/v1/goals/notes/${goal._id}/${noteToDelete._id}`);

            updateGoal(updatedGoal, false);


        } catch (err: any) {

            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

        }

    }

    const editNote = async (noteToEdit: IZenGoalNote)=>{
        setNote(noteToEdit);
        setShowAddNoteModal(true);
    }

    useEffect(
        ()=>{

            setNoteSaved(false);

        },[note.content]
    )


    return (
        <div className='goal-notes-section mb-4'>

            <div className='mb-4 pt-4 md:max-w-4xl mx-auto'>

                <p className='mb-2'>
                    You ever have a random thought (possibly at 3am) related to your goal or project and wish there was
                    a
                    place to quickly jot it down?
                    This is that place! Store also documents and links to helpful websites.
                </p>

                <ZenButton label={'Add new note'} zenType={'success'} className={'float-right'} onClick={ ()=> {
                    setNote({content: '', index: 0})
                    setShowAddNoteModal(true);
                } } type='button' />

                <div className='clear-both' />
            </div>

            { !goal.notes.length &&
                <div className='no-goal-notes flex'>
                    <span className='mx-auto'>You have no notes. Click on the button above to add a new note.</span>
                </div>
            }

            <div className='notes-section grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 '>

                {
                    goal.notes.map(
                        (goalNote: IZenGoalNote) => <GoalNote key={goalNote._id?.toString()} note={goalNote} editNote={ ()=>{ editNote(goalNote) } } deleteNote={ ()=>{ deleteNote(goalNote) } } />
                    )
                }

            </div>

            { goal._id &&
                <ZenModal
                    showModal={showAddNoteModal}
                    title={'Add new note'}
                    closeModal={ ()=>{  setShowAddNoteModal(false) } }
                    buttons={<ModalButtons saveNote={ ()=>{ addOrEditNote() } } saved={ noteSaved } />}
                >
                    <GoalNoteModal
                        note={note}
                        setNote={setNote}/> </ZenModal>
            }


        </div>
    )

}

export default GoalNotes

import React, {useContext, useEffect, useRef, useState} from "react";
import {ETaskPriority, IZenChecklistItem, IZenTask} from "@yellowmelon/zen-global-types";
import {DragSourceMonitor, useDrag, useDrop} from "react-dnd";
import {Identifier} from "dnd-core";
import {DraggableItemTypes, KanbanBoardContext} from "../../index";
import * as DOMPurify from 'dompurify';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as faStarSolid} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-regular-svg-icons";
import {EAlertType, GlobalAlertContext} from "../../../../../components/GlobalAlert";
import ApiClient from "../../../../../lib/ApiClient";

interface Props {
    index: number,
    task: IZenTask | null,
    onClick?: (task: IZenTask) => void,
    doPowerUp?: (task: IZenTask) => void,
    moveItem: (item: IZenTask, dragIndex: number, hoverIndex: number) => void
    onDrop: (oldTask: IZenTask) => void
    columnRef: React.MutableRefObject<null>
    tasklistRef: React.MutableRefObject<null>
}

export interface IDragItem {
    index: number
    task: IZenTask
}

const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;
const apiClient = new ApiClient();

const KanbanCard = ({index, task, onClick, moveItem, onDrop, doPowerUp, columnRef, tasklistRef}: Props) => {

    const cardRef = useRef(null);
    const {showAlert} = useContext(GlobalAlertContext);
    const {getTaskList} = useContext(KanbanBoardContext);
    const scrollContainer: Element | null = document.querySelector('.kanban-column-container');
    const [coordinates, setCoordinates] = useState({x: 0, y: 0});
    const [draggable, setDraggable] = useState<boolean>(!isTouchDevice);

    const updatePriority = async ()=>{

        if(!task) return;

        const newPriority: ETaskPriority = (!task.priority || task.priority === ETaskPriority.normal) ? ETaskPriority.high : ETaskPriority.normal

        console.log(newPriority);

        try {

            await apiClient.put<IZenTask>(`api/v1/tasks/${task._id}`, {
                payload: {
                    task: {...task, priority: newPriority  } as IZenTask
                }
            })

            getTaskList();

        } catch (err: any) {
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }

    }

    const [{handlerId, isOver}, drop] = useDrop<
        IDragItem,
        void,
        { handlerId: Identifier | null, isOver: boolean }>(
        {
            accept: DraggableItemTypes.TASK,
            collect(monitor) {
                return {
                    handlerId: monitor.getHandlerId(),
                    isOver: monitor.isOver()
                }
            },
            hover(item: IDragItem, monitor) {

                if (!cardRef.current) {
                    return
                }

                const dragIndex = item.index
                const hoverIndex = index

                moveItem(item.task, dragIndex, hoverIndex)

            },
            drop: (update: unknown, monitor) => {

                const {task} = update as IDragItem
                onDrop(task);

            },
        })

    const [{isDragging, clientOffset}, drag, preview] = useDrag({
        type: DraggableItemTypes.TASK,
        canDrag: draggable,
        item: () => {

            return {task, index}
        },
        collect: (monitor: DragSourceMonitor<{ task: IZenTask | null; index: number; }, unknown>) => {

            return {
                isDragging: monitor.isDragging(),
                clientOffset: monitor.getClientOffset()
            }

        },
    })

    const [startMove, setStartMove] = useState<number>(0)

    const handleTouchStart = (event: any) =>{

        if(!event?.touches[0]?.clientX) return;

        setTimeout(
            ()=>{
                setDraggable(true);
            }, 500
        )

        setStartMove(event.touches[0].clientX)

    }

    const handleTouchEnd = (event: any) =>{

        setDraggable(false);

    }

    // Manage scrolling on mobiles
    const handleTouchMove = (event: any) => {

        const touch = event.touches[0];

        if (touch) {
            const x = touch.clientX;
            const y = touch.clientY;

            setCoordinates({x, y});
        }

    };


    useEffect(
        () => {

            if (!isDragging || !clientOffset || !tasklistRef.current) return;

            const tasklistElement = tasklistRef.current as HTMLElement;
            const tasklistClient = tasklistElement?.getBoundingClientRect();

            const cardTop = clientOffset.y;
            const tasklistTop = tasklistClient.top

            if(tasklistTop + 100 > cardTop){

                // @ts-ignore
                tasklistRef.current?.scrollBy(
                    {
                        top: -200,
                        behavior: 'smooth'
                    }
                )
            }


        }, [isDragging, clientOffset]
    )

    const scrollAmount = 100;

    useEffect(
        () => {

            // This function is purely to scroll columns left and right on mobile devices
            if (!scrollContainer) return;


            if (isDragging && !!startMove && (coordinates.x - startMove > 100)) {

                scrollContainer.scrollBy({left: scrollAmount, behavior: 'smooth'})
                setStartMove(0);

            } else if (isDragging && !!startMove && (startMove - coordinates.x > 100)) {

                scrollContainer.scrollBy({left: -scrollAmount, behavior: 'smooth'})
                setStartMove(0);

            }


        }, [coordinates, isDragging]
    )

    drag(drop(cardRef))
    // drop(preview(cardRef))

    // If there are no items in the column put a dummy div in so that tasks can be moved to it
    if (!task) {
        return (
            <div
                style={{padding: 10}}
                ref={cardRef}
                data-handler-id={handlerId}
            >&nbsp;</div>
        )
    }

    return (
        <div
            ref={cardRef}
            data-handler-id={handlerId}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}

            onClick={() => {
                !!onClick && onClick(task);
            }}

            // style={{opacity: isOver ? 0.5 : 1}}
            // style={{background: isOver ? 'red' : 'white'}}
            className={`${isOver ? 'rotate-3 shadow-lg' : ''} kanban-card ${task.priority === ETaskPriority.high ? 'border-2 border-red-700' : ''} select-none group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 cursor-pointer mb-2 last-of-type:mb-0`}>
            <div className="p-4 pb-0">

                <div className="mb-2 overflow-hidden">

                    <div className='title-div flex'>
                        <h3 className="font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200 mb-1">
                            {task.title}
                        </h3>
                    </div>

                    <p className="text-sm text-gray-500 text-left"
                       dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(task.description.split(' ').splice(0, 20).join(' ') ) }}>
                    </p>

                    <div className='card-icons py-2 flex justify-end align-middle'>

                        {
                            !!task.checklist.length &&
                            <span className={`checklist-count ml-auto ${task.checklist.filter( (item: IZenChecklistItem) => item.completed ).length < task.checklist.length ? 'bg-red-600' : 'bg-green-700'} text-white text-xs leading-loose px-2 rounded-md`}>
                                { `${task.checklist.filter( (item: IZenChecklistItem) => item.completed ).length}/${task.checklist.length}` }
                            </span>
                        }

                        <button
                            onClick={(ev: any)=>{ ev.preventDefault(); ev.stopPropagation(); updatePriority() }}
                            className='ml-2'>

                            { task.priority == ETaskPriority.high ?
                                <FontAwesomeIcon key='key-1' style={{color: 'red', fontSize: '1rem'}} icon={faStarSolid}/> :
                                <FontAwesomeIcon key='key-2' style={{fontSize: '1rem'}} icon={faStar}/>
                            }

                        </button>

                    </div>

                </div>

            </div>
        </div>
    )

}

export default KanbanCard

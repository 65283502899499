import React, {useContext, useState} from "react";
import ZenButton from "../../../../../../components/ZenButton"
import {EAlertType, GlobalAlertContext} from "../../../../../../components/GlobalAlert";
import ApiClient from "../../../../../../lib/ApiClient";
// @ts-ignore
import RobotGif from "../../../../../../../assets/img/robot.gif";
import GoalSuggestionCard from "../../../../../AnalyseGoal/GoalSuggestionsSection/GoalSuggestionCard";
import {IZenTask, IZenTasklistItem} from "@yellowmelon/zen-global-types";


interface Props {
    task: IZenTask
    updateTask: (updatedTask: IZenTask)=> void
}

const apiClient = new ApiClient();

const TasklistSection = ({task, updateTask}: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [taskList, setTaskList] = useState<IZenTasklistItem[]>(task.taskList && task.taskList.length ? task.taskList : []);
    const {showAlert} = useContext(GlobalAlertContext)

    const getTaskList = ()=>{

        setLoading(true);

        apiClient.put<{tasklist: IZenTasklistItem[]}>(`api/v1/tasks/analyse/tasklist/${task._id}`).then(
            (response: {tasklist: IZenTasklistItem[]})=>{

                setLoading(false);
                setTaskList(response.tasklist);

                // Update task with new suggestions
                apiClient.put<IZenTask>(`api/v1/tasks/tasklist/${task._id}`,
                    {
                        payload:
                            {tasklist: response.tasklist}
                    }).then(
                    (updatedTask) => {

                        updateTask(updatedTask);

                    }
                ).catch(
                    (err: any) => {

                        console.error(`Sorry but an error occurred: ${err.message}`);
                        showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

                    }
                )


            }
        ).catch(
            (err) => {

                setLoading(false);
                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const deleteTask = (suggestionId: string) => {

        if (!confirm('Delete item? This action cannot be undone')) return;

        apiClient.put<IZenTask>(`api/v1/tasks/suggestions/${task._id}/${suggestionId}`).then(
            (updatedTask: IZenTask) => {

                updateTask(updatedTask)

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    return (
        <div className={'suggestions-accordion-section'}>

            <div className={'suggestions-container'}>

                <div className={'mb-4'}>

                    <p className={'px-1 text-sm mb-4'}>
                        Use the magic of artificial intelligence to create a task list: a set of tasks that will lead
                        to you accomplishing your goal.
                    </p>

                    <ZenButton
                        label={`Give me a task list`}
                        zenType={'primary'}
                        onClick={getTaskList}/>

                    {
                        loading &&
                        <>
                            <div className='flex align-middle justify-center pt-1'>
                                <img className='w-[100]' src={RobotGif}/>
                            </div>
                            <label className='font-bold m-1'>Working on it...</label>
                        </>
                    }

                </div>

                <div className='task-container'>

                    {!!taskList.length &&
                        <>
                            <div className={'text-sm py-2'}>
                                Here is a task list to achieve your goal: <span
                                className={'font-bold'}>{task.title}</span>
                            </div>

                        </>
                    }

                    <div className={'py-4'}>

                        <ul className='max-h-[30rem] overflow-y-auto'>
                            {
                                taskList.map(
                                    (task: IZenTasklistItem, index: number) =>
                                        <li key={index}>
                                            <GoalSuggestionCard
                                                item={task}
                                                createTask={ ()=>{ alert('not implemented') } }
                                                deleteItem={() => {
                                                    task?._id && deleteTask(task._id.toString())
                                                }}
                                            />
                                        </li>
                                )
                            }
                        </ul>

                    </div>


                </div>

            </div>

        </div>
    )

}

export default TasklistSection

import React, {useContext, useEffect, useState} from 'react';
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import ApiClient from "../../../lib/ApiClient";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import ZenMiniAlert, {EMiniAlertType} from "../../../components/ZenMiniAlert";

const apiClient = new ApiClient();

const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]{8,}$/;


const ResetPassword = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const token = searchParams.get('token');

    const {showAlert} = useContext(GlobalAlertContext);
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [passwordValid, setPasswordValid] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<{password: boolean, confirm: boolean}>({password: false, confirm: false});
    const [loading, setLoading] = useState<boolean>(false);

    const resetPassword = async (ev: any)=>{

        ev.preventDefault();
        ev.stopPropagation();

        try{

            setLoading(true);
            await apiClient.put(`auth/v1/password/reset`, {payload: {newPassword: newPassword, token}});
            setLoading(false);

            showAlert(EAlertType.success, `Your password has been successfully updated, you will now be redirected to the login page.`)

            setTimeout(
                ()=>{
                    navigate('/zenauth/login');
                }, 3000
            )


        }catch(error: any){

            setLoading(false);
            console.error(error);

            if(error.status === 401){
                return showAlert(EAlertType.danger, `Sorry but an error occurred with this request: invalid token. Possibly your token has expired.
                Please try the reset password process again or contact support@goalzen.ai if the problem persists.`,
                    {label: 'Ok'}
                );
            }

            showAlert(EAlertType.danger, `Sorry but an error occurred with this request: please try again and contact support@goalzen.ai if the problem persists.`);

        }

    }

    useEffect(() => {

        setPasswordValid(passwordRegex.test(newPassword));

    }, [newPassword]);

    return (
        <div className='page-content forgot-password-page p-2'>

            <div className='md:w-1/2 md:mx-auto'>

                <div
                    className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Reset Your Password</h1>

                            { !token &&
                                <ZenMiniAlert message={'This page requires the use of a token which you do not have. ' +
                                    'Please follow the link in the email that was sent to you when you reset your password.'}
                                              type={EMiniAlertType.danger}/>
                            }

                            <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
                                Remember your password? &nbsp;
                                <Link className={'text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500'}
                                      to={'/zenauth/login'}>
                                    Sign in here
                                </Link>
                            </p>
                        </div>

                        <div className="mt-5">
                            <form onSubmit={resetPassword}>
                                <div className="grid gap-y-4">
                                    <div>
                                        <label htmlFor="password" className="block text-sm mb-2 dark:text-white">
                                            New Password (minimum 8 characters, at least one alphabetic letter, one
                                            number and one special character - e.g., ! @ # $ % ^ & *)</label>
                                        <div className={'relative mb-2'}>
                                            <input id="password"
                                                   type={showPassword.password ? 'text' : 'password'}
                                                   className="py-3 ps-4 pe-10 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                                   placeholder="Enter password"
                                                   value={newPassword}
                                                   onChange={e => setNewPassword(e.target.value)}
                                            />
                                            <button type="button"
                                                    onClick={() => {
                                                        setShowPassword({
                                                            ...showPassword,
                                                            password: !showPassword.password
                                                        })
                                                    }}
                                                    className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                                <svg className="shrink-0 size-3.5" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2"
                                                     strokeLinecap="round" strokeLinejoin="round">
                                                    <path className="hs-password-active:hidden"
                                                          d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                    <path className="hs-password-active:hidden"
                                                          d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                                    <path className="hs-password-active:hidden"
                                                          d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                                    <line className="hs-password-active:hidden" x1="2" x2="22" y1="2"
                                                          y2="22"></line>
                                                    <path className="hidden hs-password-active:block"
                                                          d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                                    <circle className="hidden hs-password-active:block" cx="12" cy="12"
                                                            r="3"></circle>
                                                </svg>
                                            </button>
                                        </div>

                                        {!!newPassword.length && !passwordValid &&
                                            <ZenMiniAlert message={'please enter a valid password'}
                                                          type={EMiniAlertType.danger}/>
                                        }
                                    </div>

                                    <div>
                                        <label htmlFor="confirmPassword" className="block text-sm mb-2 dark:text-white">
                                            Confirm Password
                                        </label>
                                        <div className={'relative mb-2'}>
                                            <input id="confirmPassword"
                                                   type={showPassword.confirm ? 'text' : 'password'}
                                                   className="py-3 ps-4 pe-10 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                                   placeholder="Enter password"
                                                   value={confirmPassword}
                                                   onChange={e => setConfirmPassword(e.target.value)}
                                            />
                                            <button type="button"
                                                    onClick={() => {
                                                        setShowPassword({
                                                            ...showPassword,
                                                            confirm: !showPassword.confirm
                                                        })
                                                    }}
                                                    className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600 dark:text-neutral-600 dark:focus:text-blue-500">
                                                <svg className="shrink-0 size-3.5" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2"
                                                     strokeLinecap="round" strokeLinejoin="round">
                                                    <path className="hs-password-active:hidden"
                                                          d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                                    <path className="hs-password-active:hidden"
                                                          d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                                    <path className="hs-password-active:hidden"
                                                          d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                                    <line className="hs-password-active:hidden" x1="2" x2="22" y1="2"
                                                          y2="22"></line>
                                                    <path className="hidden hs-password-active:block"
                                                          d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                                    <circle className="hidden hs-password-active:block" cx="12" cy="12"
                                                            r="3"></circle>
                                                </svg>
                                            </button>
                                        </div>

                                        {!!confirmPassword.length && (confirmPassword !== newPassword) &&
                                            <ZenMiniAlert message={'please make sure passwords match'}
                                                          type={EMiniAlertType.danger}/>
                                        }
                                    </div>

                                    <button type="submit"
                                            disabled={!passwordValid || (confirmPassword !== newPassword)}
                                            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                                        Reset password
                                    </button>

                                    { loading &&
                                        <div className={'flex items-center justify-center'}>
                                            <ZenMiniAlert message={'please wait'} type={EMiniAlertType.info}/>
                                        </div>
                                    }

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )

}

export default ResetPassword

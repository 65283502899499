import React, {useContext, useEffect, useState} from "react";
import {EAlertType, GlobalAlertContext} from "../../components/GlobalAlert";
import Select from "react-select";
import * as TimeZoneList from "../../../assets/timezones/timezoneList.json";
import {ELoginError, ITimezoneItem, IUser} from "@yellowmelon/zen-global-types";
import {AuthContext} from "../../components/Authenticate";
import ApiClient, {IApiRejectReason} from "../../lib/ApiClient";
import {IFieldValidation, ISelectValueOption} from "../Auth/Register";

const apiClient = new ApiClient();

interface IAccountUpdateForm{
    firstname: string;
    lastname: string;
    email: string;
    timezone: string;
}

interface IAccountUpdateValidation {
    firstname: IFieldValidation;
    lastname: IFieldValidation;
    email: IFieldValidation;
}

const Account = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const {user} = useContext(AuthContext);


    const [accountUpdateForm, setAccountUpdateForm] = useState<IAccountUpdateForm>({
        firstname: '',
        lastname: '',
        email: '',
        timezone: '',
    })

    const [validation, setValidation] = useState<IAccountUpdateValidation>(
        {
            firstname: {valid: true, dirty: false},
            lastname: {valid: true, dirty: false},
            email: {valid: true, dirty: false},
        }
    )

    const [formValid, setFormValid] = useState<boolean>(true);
    const {showAlert} = useContext(GlobalAlertContext);

    useEffect(
        () => {

            const keys: string[] = Object.keys(validation);
            let valid = true;

            keys.forEach(
                (key: string) => {

                    // @ts-ignore
                    if (!validation[key].valid) {
                        valid = false;
                    }

                }
            )

            setFormValid(valid);


        }, [validation]
    )

    const userTimezone = TimeZoneList.find((tzItem: ITimezoneItem) => tzItem.tzCode === Intl.DateTimeFormat().resolvedOptions().timeZone);

    const defaultTimezone: ISelectValueOption | undefined = userTimezone ? {
        value: userTimezone.tzCode,
        label: userTimezone.label
    } : undefined;

    const [timezone, setTimezone] = useState<ISelectValueOption | null>(defaultTimezone ? defaultTimezone : null)

    useEffect(
        ()=>{

            if(!user) return

            setAccountUpdateForm(
                {
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    timezone: user.timezone
                }
            )

            const timezoneObject = TimeZoneList.find((tzItem: ITimezoneItem) => tzItem.tzCode === user.timezone);

            if(timezoneObject){
                setTimezone( {label: timezoneObject.label, value: timezoneObject.tzCode} )
            }else{
                setTimezone({ label: 'UTC', value: 'etc/UTC' });
            }

        },[user]
    )



    const validateField = (field: string) => {

        switch (field) {

            case 'firstname':
                return setValidation({...validation, firstname: {valid: !!accountUpdateForm.firstname.length, dirty: true}});

            case 'lastname':
                return setValidation({...validation, lastname: {valid: !!accountUpdateForm.lastname.length, dirty: true}});

            case 'email': // Only a basic check for validation
                return setValidation({
                    ...validation,
                    email: {valid: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(accountUpdateForm.email), dirty: true}
                });

            default:

        }


    }

    const handleInput = (field: string, value: string | boolean) => {

        setAccountUpdateForm({...accountUpdateForm, [field]: value})

    }

    const submit = (ev: any) => {

        ev.preventDefault();
        setLoading(true);

        accountUpdateForm.timezone = timezone?.value ? timezone?.value : 'etc/UTC';

        apiClient.put<{ user: IUser, token: string }>(`api/v1/user`, {payload: {user: {...accountUpdateForm}}}).then(
            ({user, token}) => {

                setLoading(false);
                localStorage.setItem('zen_token', token);

                setSuccess(true);

                setTimeout(
                    ()=>{
                        setSuccess(false);
                    }, 3000
                )

            }
        ).catch(
            (err: IApiRejectReason) => {

                console.error(err)
                let errorMessage = ''

                switch (err.reason) {

                    case ELoginError.USER_ALREADY_REGISTERED:

                        errorMessage = 'Sorry but this email address is already registered. Please login or try another email address.'
                        break;

                    default:

                        errorMessage = `An unexpected error has occurred: ${err?.message}`

                }

                setLoading(false);
                showAlert(EAlertType.danger, errorMessage, {label: 'Ok'})

            }
        )

    };


    return (
        <div className='page-content register-page py-10 mb-10'>

            <div className='md:w-1/2 md:mx-auto'>

                <div
                    className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">

                        <div className="text-center">
                            <h2 className="text-3xl dark:text-white">Update your details</h2>
                        </div>

                        <div className="mt-5">

                            <form className='preline-form' onSubmit={(ev) => {
                                submit(ev)
                            }}>

                                <div className="grid gap-y-4">
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm mb-2 dark:text-white">First
                                            name<sup>*</sup></label>
                                        <div className="relative">
                                            <input type="text"
                                                   id="first-name"
                                                   value={ accountUpdateForm.firstname }
                                                   onChange={(event) => {
                                                       handleInput('firstname', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('firstname')
                                                   }}
                                                   className="text-input"
                                                   aria-describedby="email-error"/>
                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {validation.firstname.dirty && !validation.firstname.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">this
                                                field is
                                                required</p>}
                                    </div>

                                    <div>
                                        <label htmlFor="last-name" className="block text-sm mb-2 dark:text-white">Last
                                            name<sup>*</sup></label>
                                        <div className="relative">

                                            <input type="text"
                                                   id="last-name"
                                                   value={ accountUpdateForm.lastname }
                                                   onChange={(event) => {
                                                       handleInput('lastname', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('lastname')
                                                   }}
                                                   className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                   aria-describedby="email-error"/>

                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>

                                        </div>

                                        {validation.lastname.dirty && !validation.lastname.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">this
                                                field is required</p>}

                                    </div>

                                    <div>

                                        <label htmlFor="email" className="block text-sm mb-2 dark:text-white">Email
                                            address<sup>*</sup></label>

                                        <div className="relative">

                                            <input type="email"
                                                   id="email"
                                                   value={ accountUpdateForm.email }
                                                   onChange={(event) => {
                                                       handleInput('email', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('email')
                                                   }}
                                                   className="text-input"
                                                   aria-describedby="email-error"/>
                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {validation.email.dirty && !validation.email.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">Please include a valid email
                                                address so we can get back to you</p>}
                                    </div>

                                    <div className='mb-5'>

                                        <label htmlFor="timezone"
                                               className="block text-sm mb-2 dark:text-white">Timezone</label>

                                        <Select
                                            id='timezone'
                                            options={
                                                TimeZoneList.map(
                                                    (item: ITimezoneItem): ISelectValueOption =>
                                                        ({value: item.tzCode, label: item.label}))
                                            }
                                            value={ timezone }
                                            onChange={(item: any) => {
                                                !!item && setTimezone(item)
                                            }}
                                        />

                                    </div>

                                    { success &&
                                        <span
                                            className="text-xs bg-green-500 text-white mt-2 p-1 text-center rounded-md">Account details updated successfully</span>
                                    }

                                    <button type="submit"
                                            disabled={!formValid}
                                            className="py-3 px-4 inline-flex justify-center items-center gap-2 disabled:cursor-not-allowed rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">

                                        {loading && <span>please wait...</span>}

                                        {!loading && formValid && <span>Update my details</span>}

                                        {!formValid && <span>Please complete the form correctly</span>}

                                    </button>

                                </div>

                            </form>

                        </div>

                    </div>

                </div>

            </div>


        </div>
    )


}

export default Account

import ApiClient from "../ApiClient";
import {IZenAIMessage} from "@yellowmelon/zen-global-types";

const apiClient = new ApiClient();

// Manage chat with the AI on the server.
export default class AIChatManager {

    private chatHistory: IZenAIMessage[] = [];
    private sessionId = '';

    async loadChat(sessionId: string) {

        try {

            const chatHistory = await apiClient.post<IZenAIMessage[]>('api/v1/goalchat', {payload: {goalid: sessionId}})
            this.chatHistory = chatHistory;
            this.sessionId = sessionId

            return chatHistory;

        } catch (err: any) {

            const error = `[mwiero] Error : ${err.message}`
            throw new Error(error);

        }

    }

    // Send a message and receive AI history in response
    async sendMessage(message: string) {

        if (!this.sessionId) throw new Error('Need session id for chat')

        try {

            const chatHistory = await apiClient.post<IZenAIMessage[]>('api/v1/goalchat', {
                payload: {
                    goalid: this.sessionId,
                    message
                }
            });

            this.chatHistory = chatHistory;
            return chatHistory;

        } catch (err: any) {

            const error = `[mwiero] Error : ${err.message}`
            throw new Error(error);

        }

    }

}

import React from "react"
import ReactDOM from "react-dom/client"
import App from './src/App';
import './src/lib/i18n/i18n'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App/>
);


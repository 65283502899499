import React, {useEffect, useState} from "react";
import {Schema} from "mongoose";
import {Link} from "react-router-dom";

interface IZenDropdownOption {
    _id?: string | Schema.Types.ObjectId;
    name: string;
    link?: { label: string, url: string }
    tooltip?: React.ReactNode
}


interface Props<T extends IZenDropdownOption> {
    disabled?: boolean
    selectNoneOption?: boolean
    selectedItem: string | undefined | null; // Items must have an id
    items: T[]
    onClick: (index: number | null) => void
    className?: string
}

const ZenDropdown = <T extends IZenDropdownOption, >({
                                                         selectNoneOption = false,
                                                         disabled = false,
                                                         items,
                                                         selectedItem,
                                                         onClick,
                                                         className
                                                     }: Props<T>) => {

    const [selectedItemCaption, setSelectedItemCaption] = useState<string>(selectNoneOption || !items[0]?.name ? 'None selected' : items[0].name)
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    useEffect(
        () => {

            const item = items.find((item: T) => item?._id === selectedItem);

            if (typeof item === 'undefined') {
                setSelectedItemCaption('none selected');
                return;
            }

            if (typeof item?._id === 'undefined') {
                console.error(`item does not have an _id`);
            }

            setSelectedItemCaption(item.name)

        }, [items, selectedItem]
    )

    return (
        <div className={`relative ${!!className ? className : ''}`}>

            <button id=""
                    disabled={disabled}
                    onClick={(ev) => {
                        ev.stopPropagation();
                        setDropdownOpen(!dropdownOpen)
                    }}
                    type="button"
                    className="py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800
                    dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                {selectedItemCaption}
                <svg className="rotate-180 w-2.5 h-2.5 text-gray-600" width="16" height="16"
                     viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                          stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </button>

            <ul
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                }}
                className={`absolute ${dropdownOpen ? '' : 'hidden'} w-72 z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 dark:bg-gray-800
                    dark:border dark:border-gray-700 dark:divide-gray-700`}>
                {
                    selectNoneOption &&
                    <li key={'none'}
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                        <button
                            className={'border-0 bg-none w-full'}
                            onClick={(ev) => {
                                setDropdownOpen(false);
                                onClick(null)
                            }}
                        >
                            Select None
                        </button>
                    </li>

                }

                {
                    items.map(
                        (item: T, index: number) => (
                            <li key={index}
                                className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            >
                                {!item.link &&
                                    <button
                                        className={'border-0 bg-none w-full'}
                                        onClick={(ev) => {
                                            setDropdownOpen(false);
                                            onClick(index)
                                        }}>

                                        {item.name} {item.tooltip && <span className={'ml-2'}>{item.tooltip}</span>}
                                    </button>
                                }

                                { item.link &&
                                    <Link to={ item.link.url } >{ item.link.label }</Link>
                                }

                            </li>
                        )
                    )
                }

            </ul>
        </div>
    )

}

export default ZenDropdown

import React from 'react'
import ZenButton from "../../components/ZenButton";

interface Props{
    className?: string
    activePage: string
    goalid: string
}

const GoalNav = ({activePage, goalid, className = ''}: Props)=>{

    return(
        <>
            <div className={`goal-nav hidden md:flex items-center justify-center ${className ? className : ''}`}>

                <ZenButton label='overview' zenType={activePage === 'overview' ? 'primary' : 'default'}
                           linkTo={`/goal/${goalid}/overview`} className='md:mr-4 w-60 p-2 md:p-5' size='lg'/>

                <ZenButton label='chat' zenType={activePage === 'chat' ? 'primary' : 'default'}
                           linkTo={`/goal/${goalid}/chat`} className='md:mr-4 w-60' size='lg'/>

                <ZenButton label='suggestions' zenType={activePage === 'suggestions' ? 'primary' : 'default'}
                           linkTo={`/goal/${goalid}/suggestions`} className='md:mr-4 w-60' size='lg'/>

                <ZenButton label='resources' zenType={activePage === 'resources' ? 'primary' : 'default'}
                           linkTo={`/goal/${goalid}/resources`} className='md:mr-4 w-60' size='lg'/>

                <ZenButton label='notes' zenType={activePage === 'notes' ? 'primary' : 'default'}
                           linkTo={`/goal/${goalid}/notes`} className='w-60' size='lg'/>

            </div>

        </>
    )

}

export default GoalNav

import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {EActiveStatus, IZenAIMessage, IZenGoal} from "@yellowmelon/zen-global-types";
import {GlobalContext} from "../../../lib/GlobalContextProvider";
import ApiClient from "../../../lib/ApiClient";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import AIChatManager from "../../../lib/AIChatManager/AIChatManager";
import AIChat from "../../../components/AIChat";


const apiClient = new ApiClient()



const GoalOverview = () => {


    const {goalid} = useParams();
    const [goal, setGoal] = useState<IZenGoal | null>({
        title: '',
        description: '',
        index: 0,
        taskList: [],
        suggestions: [],
        activeStatus: EActiveStatus.active,
        resources: [],
        notes: []
    });

    const [chatManager, setChatManager] = useState<AIChatManager | null>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const {currentContext} = useContext(GlobalContext);
    const [chatHistory, setChatHistory] = useState<IZenAIMessage[]>([]);
    const {showAlert} = useContext(GlobalAlertContext);

    useEffect(
        () => {
            getGoal();
        }, []
    )

    const getGoal = () => {

        setLoading(true);

        apiClient.get<IZenGoal>(`api/v1/goals/${goalid}`).then(
            (responseGoal) => {

                setGoal(responseGoal);
                setChatManager(new AIChatManager());

            }
        ).catch(
            (err) => {

                setLoading(false);
                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    useEffect(() => {

        if (!chatManager) return;

        loadChat();

    }, [chatManager]);

    const loadChat = async () => {

        if (!chatManager) return showAlert(EAlertType.danger, 'Chat manager not initialised');

        if (!goalid) return showAlert(EAlertType.danger, 'Sorry but an error occurred: no id to load ai chat');

        try {

            const updatedChat = await chatManager.loadChat(goalid);
            setChatHistory(updatedChat);

            setLoading(false);

        } catch (err: any) {

            const error = `Error loading goalchat: ${err.message}`
            showAlert(EAlertType.danger, error);

        }


    }

    useEffect(
        () => {

            setGoal({...goal, context: currentContext?._id} as IZenGoal)

        }, [currentContext]
    )


    const newChatMessage = (message: string) => {

        if (!chatManager) return showAlert(EAlertType.danger, 'Chat manager not initialised');

        setLoading(true);

        chatManager.sendMessage(message).then(
            (AIChat: IZenAIMessage[]) => {
                setLoading(false)
                setChatHistory(AIChat);
            }
        ).catch(
            (err: any) => {

                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )


    }

    if (!goal) return null;

    return (
        <div className='goal-chat-section'>

            <AIChat
                loading={loading}
                className='mb-4'
                chatHistory={chatHistory} newMessage={newChatMessage}/>

        </div>
    )

}

export default GoalOverview

import React, {ReactNode} from "react";
import {useLocation} from 'react-router-dom'
import Header from "./Header";
import './layout.less';
import PageTitle from "./PageTitle";
import packageJson from '../../package.json';

const Layout = ({children}: { children: ReactNode }) => {

  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen">
      <Header/>

      <PageTitle path={location.pathname}/>

      <section className='content-main px-4 md:px-40 mb-10 overflow-x-auto flex-grow'>

        {children}

      </section>

      <footer className="bg-black text-white py-4 px-4 md:px-40">
        <p className="text-sm text-right">
          &copy; 2024 Yellow Melon Ltd. | <i>v{packageJson.version}</i>
        </p>
      </footer>

    </div>
  )

}

export default Layout

import React, {useContext, useState} from "react";
import ZenButton from "../../../components/ZenButton";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import ApiClient from "../../../lib/ApiClient";
// @ts-ignore
import RobotGif from '../../../../assets/img/robot.gif'
import GoalSuggestionCard from "./GoalSuggestionCard";

import {
    EActiveStatus,
    ETaskPriority,
    ETaskStatus,
    IZenGoal,
    IZenGoalSuggestionModel,
    IZenTask
} from "@yellowmelon/zen-global-types";
import RobotLoadingGif from "../../../components/RobotLoadingGif";

interface Props {
    goal: IZenGoal
    updateGoal: (updatedGoal: IZenGoal, saveGoal?: boolean) => void
}

const apiClient = new ApiClient();

const GoalSuggestions = ({goal, updateGoal}: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const {showAlert} = useContext(GlobalAlertContext)

    const getSuggestions = () => {

        setLoading(true);
        const getmore = !!goal.suggestions.length

        apiClient.put<IZenGoal>(`api/v1/goals/suggestions/${goal._id}${getmore ? '?getmore=true' : ''}`).then(
            (updatedGoal) => {

                setLoading(false);
                updateGoal({...goal, suggestions: updatedGoal.suggestions}, false); // Goal is already saved, don't save it in the parent

            }
        ).catch(
            (err: any) => {

                setLoading(false)
                console.error(`Sorry but an error occurred: ${err.message}`);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`)

            }
        )


    }

    const deleteSuggestion = (suggestionId: string) => {

        if (!confirm('Delete suggestion? This action cannot be undone')) return;

        apiClient.delete<IZenGoal>(`api/v1/goals/suggestions/${goal._id}/${suggestionId}`).then(
            (updatedGoal: IZenGoal) => {

                updateGoal({...goal, suggestions: updatedGoal.suggestions}, false)

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const createTaskFromSuggestion = (suggestion: IZenGoalSuggestionModel) => {

        if (!confirm('This will create a new task in your kanban board from this suggestion. Proceed?')) return;

        const newTask: IZenTask = {
            title: suggestion.method,
            description: suggestion.description,
            priority: ETaskPriority.normal,
            status: ETaskStatus.inbox,
            context: goal?.context ? goal.context : null,
            checklist: [],
            index: 0,
            suggestions: [],
            taskList: [],
            activeStatus: EActiveStatus.active,
            notes: [],
            resources: []
        }

        apiClient.post<IZenTask>(`api/v1/tasks`,
            {
                payload: {task: newTask}
            }
        ).then(
            async (createdTask: IZenTask) => {

                if (!goal.childTasks || !createdTask._id) return;

                const childTaskList = goal.childTasks.map((childTask) => {

                    if ((childTask as IZenTask)._id === undefined) return childTask.toString();
                    return (childTask as IZenTask)._id;

                })

                // Keep a list of child tasks in the parent goal
                const goalWithTasks: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/${goal?._id}?childtasks=true`,
                    {
                        payload: {
                            goal: {
                                ...goal,
                                childTasks: [...childTaskList, createdTask._id.toString()]
                            } as IZenTask
                        }
                    })

                // Now update the status of the suggestion
                const updatedGoal: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/suggestions/${goal._id}/${suggestion._id}`,
                    {payload: {suggestion: {...suggestion, taskCreated: true} as IZenGoalSuggestionModel}})

                updateGoal({
                    ...goal,
                    childTasks: goalWithTasks.childTasks,
                    suggestions: updatedGoal.suggestions
                }, false);

                showAlert(
                    EAlertType.info,
                    `This suggestion has been turned into a task which is now available to be managed in your kanban board`
                )

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    const createGoalFromSuggestion = async (suggestion: IZenGoalSuggestionModel) => {

        try {

            const newGoal: IZenGoal = {
                title: suggestion.method,
                description: suggestion.description,
                context: goal?.context ? goal.context : null,
                index: 0,
                suggestions: [],
                taskList: [],
                activeStatus: EActiveStatus.active,
                notes: [],
                resources: []
            }

            const createdGoal: IZenGoal = await apiClient.post<IZenGoal>(`api/v1/goals`,{payload: {goal: newGoal}});

            showAlert(EAlertType.success, 'A new goal has been created, you will be able to view it on the top level goals page');


        } catch (err: any){

            console.error(err.message);
            showAlert(EAlertType.danger, `Sorry but an error occurred whilst creating this goal: ${err.message}`);

        }

    }

    return (
        <div className={'suggestions-accordion-section'}>

            <div className={'suggestions-container md:mx-8'}>

                <div className={'mb-4 flex flex-col'}>

                    <p className={'mb-4 text-center pt-4 md:pt-0'}>
                        Use the magic of artificial intelligence to suggest ways to accomplish
                        your goals. Then save suggestions, manage them, turn them into new tasks.
                    </p>

                    <ZenButton
                        className='mx-auto'
                        size='lg'
                        label={`Give me some ${!!goal?.suggestions?.length ? 'more ' : ''}suggestions`}
                        zenType={'primary'}
                        onClick={getSuggestions}/>

                    {
                        loading && <RobotLoadingGif/>
                    }

                </div>

                <div className='task-container'>

                    {!!goal?.suggestions?.length &&
                        <>
                            <div className={'text-sm py-2'}>
                                Here are {goal.suggestions.length} suggestions to achieve your goal: <span
                                className={'font-bold'}>{goal.title}</span>
                            </div>

                        </>
                    }

                    <div className={'py-4'}>

                        <ul className='goal-suggestion-list'>
                            {
                                goal?.suggestions?.map(
                                    (suggestion: IZenGoalSuggestionModel) =>
                                        <li key={suggestion.method}>
                                            <GoalSuggestionCard
                                                item={suggestion}
                                                createTask={
                                                    () => {
                                                        createTaskFromSuggestion(suggestion)
                                                    }
                                                }
                                                createGoal={
                                                    () => {
                                                        createGoalFromSuggestion(suggestion)
                                                    }
                                                }
                                                deleteItem={
                                                    () => {
                                                        suggestion?._id && deleteSuggestion(suggestion._id.toString())
                                                    }
                                                }
                                            />
                                        </li>
                                )
                            }
                        </ul>

                    </div>


                </div>

            </div>

        </div>
    )

}

export default GoalSuggestions

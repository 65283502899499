import {Editor} from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";
import React from "react";
import {IZenGoalNote} from "@yellowmelon/zen-global-types";


interface Props{
    note: IZenGoalNote,
    setNote: (note: IZenGoalNote) => void,
}

const GoalNoteModal = ({note, setNote}: Props)=>{

    return (
        <div className="p-4">

            <label className='mb-4 inline-block'>Write the text for your note below</label>

            <Editor
                apiKey='egb518kqdaeb0sk0eujh61phf6jl6pdfg47cmnmmagn6s4t9'
                value={note.content}
                onEditorChange={(editorState) => {
                    setNote(
                        {
                            ...note,
                            content: DOMPurify.sanitize(editorState)
                        }
                    )
                }}
                init={{
                    height: 400,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />

        </div>
);

}

export default GoalNoteModal;

import React, { useContext } from 'react'
import { AuthContext, IClientUser } from "../../components/Authenticate";
import { EStripeSubscriptionBillingCycle, IStripeSubscription } from '@yellowmelon/zen-global-types';


const Subscription = () => {

    const { user }: { user: IClientUser | null } = useContext(AuthContext);

    const stripePricingTableId = process.env.STRIPE_PRICING_TABLE_ID;
    const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY

    const stripeCustomerPortalUrl = process.env.STRIPE_CUSTOMER_PORTAL_URL;

    if (!user) return null;

    console.log(user);

    const stripeSubscription: IStripeSubscription | undefined = user.stripeSubscription?.[0];

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

            {!stripeSubscription &&
                <>
                    <div className="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
                        <h2 className="text-3xl lg:text-4xl text-gray-800 font-bold dark:text-gray-200">
                            Accomplish your goals with the power of Goalzen and Artificial Intelligence
                        </h2>
                    </div>


                    <div className="relative xl:w-10/12 xl:mx-auto pb-8">

                        {/* @ts-ignore */}
                        <stripe-pricing-table
                            pricing-table-id={stripePricingTableId}
                            publishable-key={stripePublishableKey}
                            customer-email={user?.email} F
                        >
                            {/* @ts-ignore */}
                        </stripe-pricing-table>


                    </div>
                </>
            }


            {stripeSubscription && (
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-slate-900 dark:border-gray-700">
                    <div className="p-4 md:p-5">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-white">
                            Your Current Subscription
                        </h3>
                        <p className="mt-2 text-gray-500 dark:text-gray-400">
                            You are currently subscribed to our { stripeSubscription.billingCycle === EStripeSubscriptionBillingCycle.YEARLY ? 'yearly' : 'monthly'} plan. Thank you for your support!
                        </p>
                        <a
                            className="mt-3 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400"
                            target='_blank' href={stripeCustomerPortalUrl}
                        >
                            Manage your subscription
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6"/></svg>
                        </a>
                    </div>
                </div>
            )}


        </div>
    )

}

export default Subscription;

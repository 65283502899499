import React, {useState} from "react";

interface Props{
    icon?: string
    character?: string
    toolTip: string
    className?: string
    iconClasses?: string
}

const Tooltip = ({icon, character = '?', iconClasses = 'bg-white', toolTip, className}: Props)=>{

    const [showTip, setShowTip] = useState<boolean>(false)

    return (
        <div className={`relative inline-block ${className ? className : ''}`}>

            {showTip && (
                <div className="w-[200px] absolute z-40 -top-2 left-1/2 transform -translate-y-full -translate-x-1/2">
                    <span className="block p-2 m-0 bg-black text-white text-xs shadow-lg relative z-10 rounded-md">
                        {toolTip}
                    </span>
                    <div className="absolute w-3 h-3 bg-black transform -translate-x-1/2 left-1/2 -mt-2 rotate-45"></div>
                </div>
            )}

            <span
                onMouseOver={ ()=>{ setShowTip(true) } }
                onMouseLeave={ ()=>{ setShowTip(false) } }
                onClick={ (ev)=>{ ev.stopPropagation(); } }
                className={ `rounded-full w-12 h-12 text-[13px] px-[8px] py-[3px] font-bold ${iconClasses} cursor-pointer` }>{ character ? character : ' ' }</span>
        </div>
    )

}

export default Tooltip


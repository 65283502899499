import React, {useState} from "react";
import {IZenGoal, IZenTask} from "@yellowmelon/zen-global-types";
import GoalOverviewCard from "./GoalOverviewCard";
import {faComments, faPenToSquare, faRectangleList, faStar} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GoalChildTaskCard from "./GoalChildTaskCard";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import ManageContext from "../../Kanban/KanbanBoard/ManageContext";
import {Link} from "react-router-dom";
import ZenMiniAlert, {EMiniAlertType} from "../../../components/ZenMiniAlert";

interface Props {
    goal: IZenGoal,
    updateGoal: (updatedGoal: IZenGoal, saveGoal?: boolean) => Promise<IZenGoal | void>
}

const GoalOverview = ({goal, updateGoal}: Props) => {

    const [contextSaved, setContextSaved] = useState<boolean>(false);

    const updateContext = async (contextId: string | null) => {

        await updateGoal({...goal, context: contextId});

        setContextSaved(true);
        setTimeout(() => setContextSaved(false), 3000);

    }


    return (
        <div className='goal-content flex flex-col'>

            <div className='md:mb-4 hidden md:flex justify-center'>

                <GoalOverviewCard goal={goal} section={'chat'} content={
                    <div>
                        {/* @ts-ignore*/}
                        <FontAwesomeIcon className='text-xl mr-2' icon={faComments}/>
                        Talk over a plan to achieve your goal with the AI
                    </div>
                }/>

                <GoalOverviewCard goal={goal} section={'suggestions'} content={
                    <div>
                        {/* @ts-ignore*/}
                        <FontAwesomeIcon className='text-xl mr-2' icon={faRectangleList}/>
                        {!!goal.suggestions.length ? goal.suggestions.length : `Let's find some`} suggestions to
                        help achieve your goal
                    </div>
                }/>

                <GoalOverviewCard goal={goal} section={'resources'} content={
                    <div>
                        {/* @ts-ignore*/}
                        <FontAwesomeIcon className='text-xl mr-2' icon={faStar}/>
                        A list of {!!goal.resources?.length ? goal.resources.length : ''} websites, books and apps to
                        help achieve your goal
                    </div>
                }/>`

                <GoalOverviewCard goal={goal} section={'notes'} content={
                    <div>
                        {/* @ts-ignore*/}
                        <FontAwesomeIcon className='text-xl mr-2' icon={faPenToSquare}/>
                        Track notes and ideas to achieve your goal
                    </div>
                }/>


            </div>

            <div className='mb-4 flex'>
                <ManageContext parentContext={!!goal.context ? goal.context as string : null} updateParentContext={updateContext} />
                { contextSaved && <ZenMiniAlert className='ml-2' message={'goal saved with new context'} type={EMiniAlertType.success} /> }
            </div>

            <div className='action-plan'>

                <div className='top-section mx-auto md:w-8/12'>

                    <div className={'strategy flex justify-between w-10/12 md:w-6/12 mx-auto mb-4'}>
                        <span className='font-bold text-lg'>Research</span>
                        <FontAwesomeIcon className='translate-y-1.5' icon={faArrowRight}/>
                        <span className='font-bold text-lg'>Plan</span>
                        <FontAwesomeIcon className='translate-y-1.5' icon={faArrowRight}/>
                        <span className='font-bold text-lg'>Execute</span>
                    </div>

                    <p className={'text-center'}>
                        Define steps to achieve your goal and
                        add them to the task list below. You can add tasks automatically
                        from the chat, suggestions and resources section, or you can add them yourself below. These
                        tasks will
                        be added to your kanban board as part of your weekly and daily workflow.
                    </p>

                    <div className='description mx-auto md:w-10/12 text-center py-4'>
                        {
                            !goal?.childTasks?.length &&
                            <div className={'p-4 border rounded-md border-gray-200'}>
                                Currently no child tasks for this goal. You can add them from your <Link className='underline' to={`/goal/${goal._id}/suggestions`} >suggestions</Link>
                                &nbsp;or <Link className='underline' to={`/goal/${goal._id}/resources`} >resources</Link> lists
                            </div>
                        }

                        {
                            !!goal?.childTasks?.length &&
                            <>
                                <div className={'py-2'}>
                                    Here is an action plan to achieve your goal: <span
                                    className={'font-bold'}>{goal.title}</span>
                                </div>

                            </>
                        }
                    </div>

                </div>


                <div className='task-container mx-auto md:w-8/12'>


                    <div className={'py-4'}>

                        <ul className='goal-child-task-list'>
                            {
                                Array.isArray(goal.childTasks) && !!goal.childTasks.length &&

                                (goal.childTasks as IZenTask[]).map(
                                    (task: IZenTask) => (
                                        <li key={task._id?.toString()}>
                                            <GoalChildTaskCard task={task}/>
                                        </li>
                                    )
                                )
                            }
                        </ul>

                    </div>


                </div>


            </div>


        </div>)

}

export default GoalOverview

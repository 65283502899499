import React from "react";

export enum EMiniAlertType{
    success = 'success',
    info = 'info',
    danger = 'danger'
}

interface Props{
    message: string;
    type?: EMiniAlertType;
    className?: string;
}

const ZenMiniAlert = ({message, type = EMiniAlertType.success, className}: Props)=>{

    const colorMap: { [key: string] : string } = {
        success: 'bg-green-500 text-white',
        info: 'bg-blue-500 text-white',
        danger: 'bg-red-500 text-white'
    }

    return(
       <span className={`text-xs ${colorMap[type]} rounded-md p-1 px-3 self-center mr-4 ${className}`}>{message}</span>
    )

}

export default ZenMiniAlert

import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {


    return (
        <div className='page-content home-page p-2'>

            <h1>Woah there</h1>

            <h2>
                Is it you? Is it us?
            </h2>

            <p className='text-center'>
                No judgement but either way we've nothing to show you here.
                Maybe <Link to='/'>head back to the home page?</Link>
            </p>

        </div>
    )

}

export default NotFound;

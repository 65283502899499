import React from 'react'
import * as DOMPurify from "dompurify";
import {IZenGoalNote} from "@yellowmelon/zen-global-types";
import ZenButton from "../../../../components/ZenButton";

interface Props{
    note: IZenGoalNote,
    editNote: () => void,
    deleteNote: () => void,
}

const GoalNote = ({note, editNote, deleteNote}: Props)=>{

    const doDelete = ()=>{

        if(!confirm('Delete note?')) return;

        deleteNote();

    }

    return <div className='note-container bg-white border shadow-sm rounded-xl px-4 pt-4 pb-2
    dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70 md:min-w-[30%] mx-2' >
        <p
            className='goal-note-content'
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(note.content) }}
        />
        <div className='goal-note-buttons flex justify-end align-middle'>

            <ZenButton
                label='edit note'
                zenType='primary'
                size='xs'
                onClick={ ()=>{ editNote() } }
                className='mr-2'
            />

            <ZenButton label='delete note' zenType='danger' size='xs' onClick={ ()=>{ doDelete() } } />

        </div>
    </div>


}

export default GoalNote

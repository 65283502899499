import React, {useContext, useEffect, useState} from 'react';
import Select from "react-select";
import {Link} from "react-router-dom";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import ApiClient, {IApiRejectReason} from "../../../lib/ApiClient";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {AuthContext} from "../../../components/Authenticate";
import {ELoginError, ITimezoneItem, IUser} from "@yellowmelon/zen-global-types";

import * as TimeZoneList from '../../../../assets/timezones/timezoneList.json';

const apiClient = new ApiClient();


export interface IFieldValidation {
    valid: boolean;
    dirty: boolean;
}

export interface IRegistrationValidation {
    firstname: IFieldValidation;
    lastname: IFieldValidation;
    email: IFieldValidation;
    password: IFieldValidation;
    passwordsMatch: IFieldValidation;
    agreeTsAndCs: IFieldValidation;
}

export interface IRegoForm extends IUser {
    passwordsMatch?: string;
    agreeTsAndCs: boolean;
}

export interface ISelectValueOption {
    value: string;
    label: string;
}

const Register = () => {

    // @ts-ignore
    const grecaptcha = window.grecaptcha;

    const [loading, setLoading] = useState<boolean>(false);

    const [regoForm, setRegoForm] = useState<IRegoForm>({
        firstname: '',
        lastname: '',
        email: '',
        timezone: '',
        password: '',
        passwordsMatch: '',
        agreeTsAndCs: false
    })

    const [validation, setValidation] = useState<IRegistrationValidation>(
        {
            firstname: {valid: false, dirty: false},
            lastname: {valid: false, dirty: false},
            email: {valid: false, dirty: false},
            password: {valid: false, dirty: false},
            passwordsMatch: {valid: false, dirty: false},
            agreeTsAndCs: {valid: false, dirty: false},
        }
    )


    const userTimezone = TimeZoneList.find((tzItem: ITimezoneItem) => tzItem.tzCode === Intl.DateTimeFormat().resolvedOptions().timeZone);

    const defaultTimezone: ISelectValueOption | undefined = userTimezone ? {
        value: userTimezone.tzCode,
        label: userTimezone.label
    } : undefined;

    const [timezone, setTimezone] = useState<ISelectValueOption | null>(defaultTimezone ? defaultTimezone : null)

    const [formValid, setFormValid] = useState<boolean>(false);
    const {showAlert} = useContext(GlobalAlertContext);
    const {doLogin} = useContext(AuthContext)

    useEffect(
        () => {

            const keys: string[] = Object.keys(validation);
            let valid = true;

            keys.forEach(
                (key: string) => {

                    // @ts-ignore
                    if (!validation[key].valid) {
                        valid = false;
                    }

                }
            )

            setFormValid(valid);


        }, [validation]
    )

    const validateField = (field: string) => {

        switch (field) {

            case 'firstname':
                return setValidation({...validation, firstname: {valid: !!regoForm.firstname.length, dirty: true}});

            case 'lastname':
                return setValidation({...validation, lastname: {valid: !!regoForm.lastname.length, dirty: true}});

            case 'email': // Only a basic check for validation
                return setValidation({
                    ...validation,
                    email: {valid: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(regoForm.email), dirty: true}
                });

            case 'password':
                return setValidation({...validation, password: {valid: !!regoForm.password?.length, dirty: true}});

            case 'passwordsMatch':
                return setValidation({
                    ...validation,
                    passwordsMatch: {valid: regoForm.password === regoForm.passwordsMatch, dirty: true}
                })

            case 'agreeTsAndCs':
                return setValidation({
                    ...validation,
                    agreeTsAndCs: {valid: !regoForm.agreeTsAndCs, dirty: true}
                })

            default:

        }


    }

    const handleInput = (field: string, value: string | boolean) => {

        setRegoForm({...regoForm, [field]: value})

    }

    const submit = async (ev: any) => {

        ev.preventDefault();
        setLoading(true);

        regoForm.timezone = timezone?.value ? timezone?.value : 'etc/UTC';

        delete regoForm.passwordsMatch;

        const googleToken = await new Promise(
            (resolve, reject) => {

                grecaptcha.ready(function() {
                    grecaptcha.execute(
                        process.env.GOOGLE_RECAPTCHA_SITE_KEY,
                        {action: 'submit'}).then(
                        (token: string) => {
                            resolve(token);
                        });
                });

            }
        )

        apiClient.post<{ user: IUser, token: string }>(`auth/v1/register`,
            {payload:
                    {
                        user: {...regoForm},
                        googleToken
                    }
            }
            ).then(
            ({ user, token }) => {

                setLoading(false);
                localStorage.setItem('zen_token', token);
                !!doLogin && doLogin();

            }
        ).catch(
            (err: IApiRejectReason) => {

                console.error(err)
                let errorMessage = ''

                switch (err.reason) {

                    case ELoginError.USER_ALREADY_REGISTERED:

                        errorMessage = 'Sorry but this email address is already registered. Please login or try another email address.'
                        break;

                    default:

                        errorMessage = `An unexpected error has occurred: ${err?.message}`

                }

                setLoading(false);
                showAlert(EAlertType.danger, errorMessage, {label: 'Ok'})

            }
        )

    };

    // This should really be combined with googleLogin on the registration
    const googleLogin = async (response: CredentialResponse) => {

        const credential = response.credential;

        if (!credential) {
            return showAlert(EAlertType.danger, 'Sorry but an error occurred: could not retrieve Google credential', {label: 'Ok'})
        }

        // Get user timezone from their browser
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {

            setLoading(true);

            const {token} = await apiClient.post<{
                user: IUser,
                token: string
            }>('auth/v1/googlelogin', {payload: {googleToken: credential, timezone}})

            setLoading(false);

            localStorage.setItem('zen_token', token);
            !!doLogin && doLogin();


        } catch (err: any) {

            showAlert(EAlertType.danger, `Sorry but an error occurred with this request: ${err.message}`, {label: 'Ok'})

        }

    }

    return (
        <div className='page-content register-page p-2'>

            <div className='md:w-1/2 md:mx-auto'>

                <div
                    className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Sign up</h1>
                            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 mb-4">
                                Already have an account?
                            </p>
                            <Link to='/zenauth/login' className="btn btn-primary btn-small font-medium">
                                Sign in here
                            </Link>
                        </div>

                        <div className="mt-5">

                            <GoogleLogin onSuccess={googleLogin} onError={() => {
                                showAlert(EAlertType.danger, `Sorry but an error occurred: Could not authorize login with Google`)
                            }}/>

                            <div
                                className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:mr-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ml-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">Or
                            </div>

                            <form className='preline-form' onSubmit={(ev) => {
                                submit(ev)
                            }}>

                                <div className="grid gap-y-4">
                                    <div>
                                        <label htmlFor="first-name" className="block text-sm mb-2 dark:text-white">First
                                            name<sup>*</sup></label>
                                        <div className="relative">
                                            <input type="text"
                                                   id="first-name"
                                                   onChange={(event) => {
                                                       handleInput('firstname', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('firstname')
                                                   }}
                                                   className="text-input"
                                                   aria-describedby="email-error"/>
                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {validation.firstname.dirty && !validation.firstname.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">this
                                                field is
                                                required</p>}
                                    </div>

                                    <div>
                                        <label htmlFor="last-name" className="block text-sm mb-2 dark:text-white">Last
                                            name<sup>*</sup></label>
                                        <div className="relative">

                                            <input type="text"
                                                   id="last-name"
                                                   onChange={(event) => {
                                                       handleInput('lastname', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('lastname')
                                                   }}
                                                   className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                   aria-describedby="email-error"/>

                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>

                                        </div>

                                        {validation.lastname.dirty && !validation.lastname.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">this
                                                field is required</p>}

                                    </div>

                                    <div>

                                        <label htmlFor="email" className="block text-sm mb-2 dark:text-white">Email
                                            address<sup>*</sup></label>

                                        <div className="relative">

                                            <input type="email"
                                                   id="email"
                                                   onChange={(event) => {
                                                       handleInput('email', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('email')
                                                   }}
                                                   className="text-input"
                                                   aria-describedby="email-error"/>
                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {validation.email.dirty && !validation.email.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">Please include a valid email
                                                address so we can get back to you</p>}
                                    </div>

                                    <div>

                                        <label htmlFor="email"
                                               className="block text-sm mb-2 dark:text-white">Timezone</label>

                                        <div className="">

                                            <Select
                                                options={
                                                    TimeZoneList.map(
                                                        (item: ITimezoneItem): ISelectValueOption =>
                                                            ({value: item.tzCode, label: item.label}))
                                                }
                                                value={timezone}
                                                onChange={(item: any) => {
                                                    !!item && setTimezone(item)
                                                }}
                                            />

                                        </div>

                                    </div>

                                    <div>
                                        <label htmlFor="password"
                                               className="block text-sm mb-2 dark:text-white">Password</label>
                                        <div className="relative">

                                            <input type="password"
                                                   id="password"
                                                   onChange={(ev) => {
                                                       handleInput('password', ev.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('password')
                                                   }}
                                                   className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                   aria-describedby="password-error"/>

                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>

                                        </div>

                                        {validation.password.dirty && !validation.password.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="password-error">6+ characters required</p>}

                                    </div>

                                    <div>
                                        <label htmlFor="confirm-password"
                                               className="block text-sm mb-2 dark:text-white">Confirm
                                            Password</label>
                                        <div className="relative">

                                            <input type="password"
                                                   id="confirm-password"
                                                   onChange={(event) => {
                                                       handleInput('passwordsMatch', event.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       validateField('passwordsMatch')
                                                   }}
                                                   className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                   required aria-describedby="confirm-password-error"/>

                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {validation.passwordsMatch?.dirty && !validation.passwordsMatch?.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="confirm-password-error">Passwords don't
                                                match</p>}
                                    </div>

                                    <div className="flex items-center">
                                        <div className="flex">
                                            <input id="remember-me"
                                                   type="checkbox"
                                                   checked={regoForm.agreeTsAndCs}
                                                   onChange={(ev) => {
                                                       handleInput('agreeTsAndCs', ev.target.checked);
                                                       validateField('agreeTsAndCs')
                                                   }}
                                                   className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"/>
                                        </div>
                                        <div className="ml-3">
                                            <label htmlFor="remember-me" className="text-sm dark:text-white">I accept
                                                the <a
                                                    className="text-blue-600 decoration-2 hover:underline font-medium"
                                                    href="#">Terms and
                                                    Conditions</a></label>
                                        </div>
                                    </div>

                                    {!validation.agreeTsAndCs.valid &&
                                        <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                           id="email-error">Please
                                            agree to the terms and
                                            conditions</p>}

                                    <button type="submit"
                                            disabled={!formValid}
                                            className="py-3 px-4 inline-flex justify-center items-center gap-2 disabled:cursor-not-allowed rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">

                                        {loading && <span>please wait...</span>}

                                        {!loading && formValid && <span>Let's get signed up!</span>}

                                        {!formValid && <span>Please complete the form correctly</span>}

                                    </button>

                                    <div className="text-xs text-gray-400 mt-4 text-center">
                                        This site is protected by reCAPTCHA and the Google{' '}
                                        <a className="underline" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
                                        <a className="underline" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>

                </div>

            </div>


        </div>
    )

}

export default Register

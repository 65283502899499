import {Link} from "react-router-dom";
import React from "react";
import {IZenGoal} from "@yellowmelon/zen-global-types";

interface Props {
    goal: IZenGoal,
    section: 'chat' | 'suggestions' | 'resources' | 'notes',
    content: React.ReactNode,
}

const GoalOverviewCard = ({goal, section, content}: Props)=>{

    return(
        <Link to={`/goal/${goal._id}/${section}`} >
            <div
                className="min-w-[20%] mx-2 bg-white border shadow-sm rounded-xl p-4 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">

                { content }

            </div>
        </Link>
    )

}

export default GoalOverviewCard

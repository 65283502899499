import React, {useEffect, useState} from 'react'
import {EZenChatMessageRoles, IZenAIMessage} from "@yellowmelon/zen-global-types";
import ZenButton from "../ZenButton";
import {marked} from "marked";
import * as DOMPurify from 'dompurify';

interface Props {
    className?: string,
    chatHistory: IZenAIMessage[],
    newMessage: (message: string) => void
    loading: boolean
}

const AIChat = ({chatHistory, newMessage, className, loading = false}: Props) => {

    const [message, setMessage] = useState<string>('');
    const [messageList, setMessageList] = useState<IZenAIMessage[]>([])

    useEffect(() => {
        setMessageList(chatHistory)
    }, [chatHistory]);

    return (
        <div className={`ai-chat-container ${className ? className : ''}`}>

            <div
                className='ai-chat-list px-2 py-4 scroll-auto flex flex-col border border-gray-200 rounded-xl p-4 dark:border-gray-700 bg-gray-50 dark:bg-slate-900'>

                {
                    !!messageList.length && messageList.map(
                        (chat: IZenAIMessage, index) => (
                            <div key={index}
                                 className={`chat-message group flex flex-col border shadow-sm rounded-xl hover:shadow-md transition  
                                 mb-2 last-of-type:mb-0 px-2 py-4 text-sm
                                 ${chat.role === EZenChatMessageRoles.assistant ? 'ml-4 bg-white ' : 'mr-2 bg-blue-500 text-white'}`}
                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(marked.parse(chat.content) as string) as string}}
                            />
                        )
                    )
                }

                {
                    loading && <div
                                    className={`chat-message group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 
                                 dark:border-gray-800 cursor-pointer mb-2 last-of-type:mb-0 px-2 py-4 text-sm mx-4`}
                    >Please wait...</div>
                }

            </div>

            <div className='flex flex-col'>

                <label className='my-2'>Type your reply</label>

                <textarea
                    className='border border-gray-200 rounded-xl mb-2'
                    disabled={loading}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            newMessage(message);
                            setMessageList([...messageList, { role: EZenChatMessageRoles.user, content: message }])
                            setMessage('')
                        }
                    }
                    }
                />

                <ZenButton disabled={loading} label='submit' zenType={'primary'} type={'button'} onClick={() => {
                    newMessage(message);
                    setMessageList([...messageList, { role: EZenChatMessageRoles.user, content: message }])
                    setMessage('')
                }}/>

            </div>

        </div>
    )

}

export default AIChat;

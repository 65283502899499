import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

interface Props{
    className?: string
    onDelete: ()=> void
    confirmMessage?: string
}

const ZenDeleteButton = ({className, onDelete, confirmMessage='Are you sure?'}:Props)=>{

    const confirmDelete = ()=>{

        if(!confirm( confirmMessage )){
            return;
        }

        onDelete();

    }

    return(
        <div className={`inline-block relative ${className}`}>
            <button
                className={`border-0 bg-none`}
                type={'button'}
                onClick={(ev) => {
                    ev.preventDefault();
                    confirmDelete()
                }}
            >
                <FontAwesomeIcon
                    icon={faTrash}/>
            </button>
        </div>
    )

}

export default ZenDeleteButton

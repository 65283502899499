import React, {useContext, useEffect, useState} from "react";
import {IZenGoal, IZenGoalResourceModel} from "@yellowmelon/zen-global-types";
import ApiClient from "../../../lib/ApiClient";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import './resources-section.less';
import RobotLoadingGif from "../../../components/RobotLoadingGif";
import ZenButton from "../../../components/ZenButton";
import GoalResourceCard from "./GoalResourceCard";

interface Props{
    goal: IZenGoal
    updateGoal: (updatedGoal: IZenGoal, saveGoal: boolean)=> void
}

const purifyConfig = {
    ALLOWED_TAGS: ['a', 'b', 'i', 'em', 'strong', 'p', 'div', 'span', 'ol', 'li', 'h3'], // add other tags as needed
    ALLOWED_ATTR: ['href', 'target', 'rel'], // add other attributes as needed
};

const GoalResources = ({goal, updateGoal}: Props)=>{

    const apiClient = new ApiClient();
    const { showAlert } = useContext(GlobalAlertContext)
    const [resources, setResources] = useState<IZenGoalResourceModel[]>(!!goal.resources && Array.isArray(goal.resources) ? goal.resources : []);
    const [loading, setLoading] = useState<boolean>(false);

    const getResources = async ()=>{

        try{

            setLoading(true);

            const updatedGoal: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/resources/${goal._id}${!!resources.length ? '?getmore=true' : ''}`);

            setLoading(false);
            updateGoal({...goal, resources: updatedGoal.resources}, false);


        }catch (err: any) {

            setLoading(false);
            console.error(err.message);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

        }

    }

    useEffect(() => {

        const resources = goal.resources;

        setResources(resources);

    }, [goal]);

    return(
        <div className='goal-resources-section'>

            <div className='flex flex-col items-center mb-8'>

                <p className='mb-4'>Suggestions for books, apps, websites and more to achieve your goal</p>

                <ZenButton
                    className='mx-auto'
                    size='lg'
                    label={`Let's find some ${!!resources.length ? 'more' : '' } resources`}
                    zenType={'success'}
                    onClick={getResources}/>

                {
                    loading && <RobotLoadingGif/>
                }

            </div>

            <div className='resource-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 -mx-2'>
                {
                    !!resources?.length && (
                        resources.map(resource => <GoalResourceCard key={resource._id?.toString()} resource={resource}/>)
                    )
                }
            </div>

        </div>
    )

}

export default GoalResources

import React, {useContext, useState} from 'react';
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import ApiClient from "../../../lib/ApiClient";
import {Link} from "react-router-dom";
import ZenMiniAlert, {EMiniAlertType} from "../../../components/ZenMiniAlert";

const apiClient = new ApiClient();

const ForgotPassword = () => {

    const {showAlert} = useContext(GlobalAlertContext);
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const resetPassword = async (ev: any)=>{

        ev.preventDefault();
        ev.stopPropagation();

        try{

            setLoading(true);
            await apiClient.post(`auth/v1/password/forgot`, {payload: {email}});
            setLoading(false);

            showAlert(EAlertType.info, `If your email address exists in our database then an email will be sent to you with instructions to reset your password.`,
                {label: 'Ok'})


        }catch(error: any){

            setLoading(false);

            if(error?.status === 429){
                return showAlert(EAlertType.danger, `You can only make this request once an hour. If you're experiencing difficulties please contact support@goalzen.ai`);
            }

            console.error(error);
            showAlert(EAlertType.danger, `Sorry but an error occurred with this request: please try again and contact support@goalzen.ai if the problem persists.`);

        }

    }

    return (
        <div className='page-content forgot-password-page p-2'>

            <div className='md:w-1/2 md:mx-auto'>

                <div
                    className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Forgot password?</h1>
                            <p className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
                                Remember your password? &nbsp;
                                <Link className={'text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500'}
                                      to={'/zenauth/login'}>
                                    Sign in here
                                </Link>
                            </p>
                        </div>

                        <div className="mt-5">
                            <form onSubmit={resetPassword}>
                                <div className="grid gap-y-4">
                                    <div>
                                        <label htmlFor="email" className="block text-sm mb-2 dark:text-white">
                                            Please enter your email address</label>
                                        <div className="relative">
                                            <input type="email" id="email" name="email"
                                                   className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                                   aria-describedby="email-error"
                                                   value={email}
                                                   onChange={e => setEmail(e.target.value)}
                                            />
                                            <div className="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                                                <svg className="size-5 text-red-500" width="16" height="16"
                                                     fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include
                                            a valid email address so we can get back to you</p>
                                    </div>

                                    {  !!email.length && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
                                        <ZenMiniAlert message={'Please enter a valid email'}
                                                      className={'w-full'}
                                                      type={EMiniAlertType.danger}/>
                                    }

                                    <button type="submit"
                                            disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
                                            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                                        Reset password
                                    </button>

                                    { loading &&
                                        <div className={'flex items-center justify-center'}>
                                            <ZenMiniAlert message={'please wait'} type={EMiniAlertType.info}/>
                                        </div>
                                    }


                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )

}

export default ForgotPassword

import React from "react";
import ZenButton from "../../../../components/ZenButton";

interface Props{
    className: string
    filterPriority: boolean
    doFilterPriority: (filterPriority: boolean) => void
}

const FilterKanbanTasks = ({className = '', filterPriority, doFilterPriority}:Props)=>{

    const i = 0;

    return (
        <div className={`filter-kanban-tasks ${!!className && className }`}>

            <ZenButton
                label='priority tasks'
                zenType='danger'
                size='sm'
                onClick={ ()=>{ doFilterPriority(!filterPriority) } }
            />

        </div>
    )

}

export default FilterKanbanTasks

import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import GoalNav from "./GoalNav";
import GoalChat from "./GoalChat";
import GoalOverview from "./GoalOverview";
import GoalSuggestions from "./GoalSuggestions";
import {IZenAIMessage, IZenGoal} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "../../components/GlobalAlert";
import ApiClient from "../../lib/ApiClient";
import NotFound from "../NotFound";
import GoalNotes from "./GoalNotes";
import GoalResources from "./GoalResources";
import ZenButton from "../../components/ZenButton";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";


const GoalPage = () => {

    const apiClient = new ApiClient();

    const {goalid} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const {showAlert} = useContext(GlobalAlertContext);

    const [goal, setGoal] = useState<IZenGoal | undefined>();
    const [activePage, setActivePage] = useState<string>('overview')

    useEffect(() => {

        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];

        setActivePage(lastSegment);

    }, [location]);

    useEffect(
        () => {

            getGoal(false).then(
                (retrievedGoal: IZenGoal | undefined) => {

                    setGoal(retrievedGoal);

                });

        }, []
    )

    const getGoal = async (saveGoal = true) => {

        try {

            const retrievedGoal = await apiClient.get<IZenGoal>(`api/v1/goals/${goalid}?childtasks=true`);
            !!saveGoal && setGoal(retrievedGoal);

            return retrievedGoal;

        } catch (err: any) {
            console.error(err)
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);
        }

    }

    const getTaskList = async () => {

        try {

            const retrievedGoal = await apiClient.put<IZenGoal>(`api/v1/goals/gettasklist/${goalid}`);
            setGoal(retrievedGoal);

        } catch (err: any) {

            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err}`);

        }

    }

    const getSuggestionsAndResources = async (goalToInitialise: IZenGoal) => {

        try {

            await apiClient.put<IZenAIMessage[]>(`api/v1/goals/resources/${goalToInitialise._id}`)
            const goalWithSuggestions = await apiClient.put<IZenGoal>(`api/v1/goals/suggestions/${goalToInitialise._id}`)

            await updateGoal({...goalWithSuggestions, initialised: true});



        } catch (err: any) {

            console.error(err)
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

        }


    }

    const updateGoal = async (updatedGoal: IZenGoal, saveGoal = true) => {

        // The goal may have been saved in a child component, in this case save the state
        // for other child components
        if (!saveGoal) {
            return setGoal(updatedGoal);
        }

        try {

            const zenGoal: IZenGoal = await apiClient.put<IZenGoal>(`api/v1/goals/${updatedGoal?._id}?childtasks=true`, {payload: {goal: updatedGoal}});
            setGoal(zenGoal);

        } catch (err: any) {
            console.error(err);
            showAlert(EAlertType.danger, `Sorry but an error occurred: ${err}`);
        }


    }

    const deleteGoal = () => {

        if (!confirm('This goal, suggestions and task list will be deleted. Child tasks will not be deleted. Proceed?')) return;

        apiClient.delete(`api/v1/goals/${goalid}`).then(
            (response) => {

                navigate('/goals');

            }
        ).catch(
            (err: any) => {

                console.error(`Error deleting goal: ${err.message}`);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    return (
        <div className='page-content analyse-goal pt-4 pb-2'>

            <div className={'mb-0 md:mb-10 flex flex-col justify-center items-center'}>

                <h1 className={'font-medium flex items-center py-2'}>
                    {goal?.title}
                </h1>

                <div className=''>

                    <ZenButton
                        className='ml-4 -mt-2'
                        type={'button'}
                        zenType={'danger'}
                        label={'Delete Goal'}
                        onClick={deleteGoal}/>

                    <ZenButton
                        className='ml-4 -mt-2'
                        type={'button'}
                        icon={faArrowLeft}
                        linkTo={'/goals'}
                        zenType={'success'}
                        label={'Back to top level goals'}/>

                </div>

            </div>

            <div className='flex flex-col'>

                <GoalNav activePage={activePage} goalid={goalid ? goalid : ''} className={'mb-0 md:mb-8'}/>

                <div className='goal-section-container'>

                    {!!goal &&
                        <Routes>
                            <Route path='overview' element={<GoalOverview goal={goal} updateGoal={updateGoal}/>}/>
                            <Route path='chat' element={<GoalChat/>}/>
                            <Route path='suggestions' element={<GoalSuggestions goal={goal} updateGoal={updateGoal}/>}/>
                            <Route path='resources' element={<GoalResources goal={goal} updateGoal={updateGoal}/>}/>
                            <Route path='notes'
                                   element={<GoalNotes goal={goal} updateGoal={updateGoal} getGoal={getGoal}/>}/>
                            <Route path='*' element={<NotFound/>}/>
                        </Routes>
                    }

                </div>

            </div>

        </div>
    )

}

export default GoalPage

import React from 'react';


import 'preline';
import './styles/fonts.css';
import './styles/base-styles.less';
import ZenRouter from "./ZenRouter";


const App = () => {

  return (
    <div className='app'>
      <ZenRouter/>
    </div>
  );

}

export default App;

import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import Layout from "../Layout";
import Authenticate from "../components/Authenticate";
import GlobalAlert from "../components/GlobalAlert";
import {GoogleOAuthProvider} from "@react-oauth/google";
import NotFound from "../pages/NotFound";
import KanbanBoard from "../pages/Kanban/KanbanBoard";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import GlobalContextProvider from "../lib/GlobalContextProvider";
import Subscription from "../pages/Subscription";
import TopGoals from "../pages/TopGoals";
import Account from "../pages/Account";
import GoalPage from "../pages/GoalPage";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";


const ZenRouter = () => {

    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

    return (
        <BrowserRouter>
            <GoogleOAuthProvider clientId="576794878401-vf6bjpifnb7slmk51i9uet58nbgf88q3.apps.googleusercontent.com">
                <Authenticate>
                    <GlobalAlert>
                        <GlobalContextProvider>
                            <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
                                <Layout>
                                    <Routes>
                                        <Route path='/' element={<KanbanBoard/>}/>
                                        <Route path='zenauth/login' element={<Login/>}/>
                                        <Route path='zenauth/register' element={<Register/>}/>
                                        <Route path='zenauth/forgot-password' element={<ForgotPassword/>}/>
                                        <Route path='zenauth/reset-password' element={<ResetPassword/>}/>
                                        <Route path='zenauth/*' element={<NotFound/>}/>
                                        <Route path='/account' element={<Account />} />
                                        <Route path='subscription' element={<Subscription/>}/>
                                        <Route path='/goal/:goalid/*' element={<GoalPage/>} />
                                        <Route path='/goals' element={<TopGoals/>}/>
                                        <Route path='*' element={<NotFound/>}/>
                                    </Routes>
                                </Layout>
                            </DndProvider>
                        </GlobalContextProvider>
                    </GlobalAlert>
                </Authenticate>
            </GoogleOAuthProvider>
        </BrowserRouter>
    )

}

export default ZenRouter

import React from "react";
import {IZenGoal} from "@yellowmelon/zen-global-types";
import {Link} from "react-router-dom";

interface Props {
    goal: IZenGoal
}

const GoalCard = ({goal}: Props) => {


    return (
        <>
            <Link to={`/goal/${goal._id}/overview`} >
                <div
                    className="flex flex-col bg-white border shadow-sm rounded-xl p-4 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">

                    <h3 className="text-lg text-gray-800 dark:text-white no-underline text-center truncate text-ellipsis">
                        {goal.title}
                    </h3>

                </div>
            </Link>

        </>
    )


}

export default GoalCard

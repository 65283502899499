import React from "react";

// @ts-ignore
import RobotGif from "../../../assets/img/robot.gif";

interface Props{
    className?: string;
}

const RobotLoadingGif = ({className = ''}: Props)=>{

    return (
        <div className={`robot-loading-gif ${ className ? className : '' }`}>
            <div className='flex align-middle justify-center pt-1'>
                <img className='w-[100]' src={RobotGif}/>
            </div>
            <label className='font-bold m-1'>Working on it...</label>
        </div>
    )

}

export default RobotLoadingGif

import React, {createContext, ReactNode, useEffect, useRef, useState} from "react";
import {ManageBearerToken} from "../lib/ManageBearerToken";
import {useLocation, useNavigate} from "react-router-dom";
import {ELLMRequestStatus, IUser} from "@yellowmelon/zen-global-types";

const tokenManager = new ManageBearerToken()

const nonAuthenticatedRoutes = [
    '/zenauth/login',
    '/zenauth/register',
    '/zenauth/forgot-password',
    '/zenauth/reset-password',
]

const websocketUrl = process.env.WEBSOCKET_URL

export type IClientUser = Omit<IUser, 'password' | 'forgotPassword'> & {
    llmStatus: ELLMRequestStatus;
    freeTrialExpires: Date | string | null;
}

export interface IAuthContext {
    user: IClientUser | null;
    loggedIn: boolean;
    doLogin: (() => void) | null;
    doLogout: (() => void) | null;
}

export const AuthContext: React.Context<IAuthContext> = createContext<IAuthContext>(
    {
        user: null,
        loggedIn: false,
        doLogin: null,
        doLogout: null
    }
)

interface Props {
    children: ReactNode
}

const Authenticate = ({children}: Props) => {

    const [user, setUser] = useState<IClientUser | null>(null);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [authComplete, setAuthComplete] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();
    const ws = useRef<WebSocket | null>(null);

    const doLogout = () => {

        setUser(null);
        tokenManager.deleteToken();
        setLoggedIn(false);
        navigate('/zenauth/login');
    }

    const doLogin = async () => {

        const tokenValid = tokenManager.tokenValid();

        if (!tokenValid) {

            setLoggedIn(false);
            setUser(null);
            setAuthComplete(true);
            return;
        }

        // Always refresh the token - mainly to retrieve subscription status
        await tokenManager.getToken(true);
        const userData: IClientUser | null = tokenManager.getUser();

        setLoggedIn(true);
        setUser(userData)
        setAuthComplete(true);

        if(!websocketUrl) {
            console.error('No websocket url');
            return;
        }

        ws.current = new WebSocket(websocketUrl);

        ws.current.onopen = async function () {

            const token = await tokenManager.getToken();

            if(!token){
                return console.error('No websocket token');
            }

            // Send the token after the connection is opened
            ws.current?.send(JSON.stringify({ type: 'authenticate', token }));

            console.log('Connected to WebSocket server');
        };

        ws.current.onmessage = function (event) {

            const message = JSON.parse(event.data);
            if (message.type === 'authenticated') {
                console.log('Authentication successful');
            } else if (message.type === 'unauthorized') {
                console.error('Authentication failed');
                ws.current?.close();
            }
        };

        ws.current.onclose = function (event) {
            console.log('Disconnected from WebSocket server');
        };


    }

    useEffect(() => {

        const initializeAuth = async () => {
            await doLogin();
        };

        initializeAuth().then();

    }, []);

    useEffect(
        () => {

            if (authComplete) {

                // If the user is logged in and tries to access a non-authenticated route, redirect to the dashboard
                if (loggedIn && nonAuthenticatedRoutes.includes(location.pathname)) {

                    navigate('/');
                    return;

                }

                // If the user is not logged in and tries to access an authenticated route, redirect to the login page
                if (!loggedIn && !nonAuthenticatedRoutes.includes(location.pathname)) {

                    navigate('/zenauth/login');

                }

                // If the user is not logged in and tries to access a non-authenticated route, redirect to the login page
                if (!nonAuthenticatedRoutes.includes(location.pathname) && !loggedIn) {
                    navigate('/zenauth/login');
                }

            }

        }, [loggedIn, authComplete, location.pathname]
    )

    if(!authComplete) return null;

    return <AuthContext.Provider value={{user, loggedIn, doLogout, doLogin}}>{children}</AuthContext.Provider>;

}

export default Authenticate
